import "./style.sass";
import { logout } from "../../redux/user/actions";
import { useCallback, useEffect, useMemo } from "react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { useParams } from "react-router-dom";
import BalancesPage from "./pages/balance";
import DepositPage from "./pages/deposit";
import ProfilePage from "./pages/Profile";
import ReferralPage from "./pages/referral";
import WithdrawPage from "./pages/withdraw";
import { useGetLoggedIn } from "../../redux/user/hooks";

type IType =
  | "profile"
  | "deposit"
  | "withdraw"
  | "referral"
  | "logout"
  | "balance";
const Account = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const isLoggedIn = useGetLoggedIn();

  const params = useParams<{ page: IType }>();
  const value = useMemo(() => params.page || "profile", [params.page]);
  const goto = useCallback(
    (key: IType) => navigate(`/account/${key}`),
    [navigate],
  );

  const renderItem = useCallback(
    (label: string, key: IType) => {
      return (
        <div
          className={`li-item ${value === key ? "selected" : ""}`}
          onClick={() => goto(key)}
        >
          {label}
        </div>
      );
    },
    [goto, value],
  );

  const doLogout = useCallback(() => {
    dispatch(logout());
    navigate("/");
  }, [dispatch, navigate]);

  // only allow access if logged in else page will break
  useEffect(() => {
    if (!isLoggedIn) navigate("/");
  }, [isLoggedIn, navigate]);

  return (
    <div className={"account-page"}>
      <div className={"ul-items"}>
        {renderItem("Profile", "profile")}
        {renderItem("Deposit", "deposit")}
        {renderItem("Withdraw", "withdraw")}
        {renderItem("Balance", "balance")}
        {renderItem("Referral", "referral")}
        <div
          className={`li-item ${value === "logout" ? "selected" : ""}`}
          onClick={doLogout}
        >
          Logout
        </div>
      </div>
      <div className={"main-section"}>
        {value === "profile" && <ProfilePage />}
        {value === "balance" && <BalancesPage />}
        {value === "deposit" && <DepositPage />}
        {value === "withdraw" && <WithdrawPage />}
        {value === "referral" && <ReferralPage />}
      </div>
    </div>
  );
};

export default Account;
