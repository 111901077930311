import { createAction } from "@reduxjs/toolkit";
import { BigNumber } from "ethers";

export type BalanceOfType = {
  bal: number;
  who: string;
  token: string;
};
export const updateBalanceOf = createAction<BalanceOfType>(
  "token/updateBalanceOf",
);

export type TotalSupplyOfType = {
  chainId: number;
  totalSupply: BigNumber;
  token: string;
};
export const updateTotalSupply = createAction<TotalSupplyOfType>(
  "token/updateTotalSupply",
);
