import jackpot from "./referrals.jpeg";
import { Link } from "react-router-dom";

const Prizes = () => {
  return (
    <>
      <div id={"prizes"} className={"box-wrapper"}>
        {/* <img src={jackpot} alt="jackpot" /> */}
        <div id="prizes-bg" style={{ backgroundImage: `url(${jackpot})` }} />
        <p>
          Refer your friends and earn a share of the trading fees and rewards.
          Place bets to not just earn rewards but also{" "}
          <Link to="/rewards">earn loyalty points</Link> to get a share of the
          upcoming MCAKE airdrop. The more bets you place, the higher you will
          go up in the <Link to="/leaderboard">leaderboard</Link>.
        </p>
      </div>
    </>
  );
};

export default Prizes;
