import { ILeaderBoard } from "../../../helpers/interface";

interface IProps {
  index: number;
  data: ILeaderBoard;
}

const LeaderboardRows = (props: IProps) => {
  const { data, index } = props;

  return (
    <div className={"table-row"}>
      <div className={"table-row-li"}>{index + 1}</div>
      <div className={"table-row-li"}>{data.username}</div>
      <div className={"table-row-li"}>${data.totalInput.toFixed(4)}</div>
      <div className={"table-row-li"}>${data.totalWon.toFixed(4)}</div>
    </div>
  );
};

export default LeaderboardRows;
