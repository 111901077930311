import { createReducer } from "@reduxjs/toolkit";
import { BigNumber } from "ethers";

import * as Actions from "./actions";

export interface TokenState {
  balanceOf: {
    [token: string]: {
      [who: string]: number;
    };
  };
  totalSupply: {
    [token: string]: BigNumber;
  };
}

export const initialState: TokenState = {
  balanceOf: {},
  totalSupply: {},
};

export default createReducer(initialState, (builder) =>
  builder
    .addCase(
      Actions.updateBalanceOf,
      (t, { payload: p }: { payload: Actions.BalanceOfType }) => {
        t.balanceOf = t.balanceOf || {};
        t.balanceOf[`${p.token}`] = t.balanceOf[`${p.token}`] || {};
        t.balanceOf[`${p.token}`][p.who] = p.bal;
      },
    )
    .addCase(
      Actions.updateTotalSupply,
      (t, { payload: p }: { payload: Actions.TotalSupplyOfType }) => {
        t.totalSupply = t.totalSupply || {};
        t.totalSupply[`${p.token}`] = p.totalSupply;
      },
    ),
);
