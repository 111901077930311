import { useCallback } from "react";
import { useDispatch } from "react-redux";
import { getUserBets } from "../../../helpers/ServerApi";
import { addBets } from "../../../redux/bets/actions";

const useUpdateGameBets = () => {
  const dispatch = useDispatch();

  return useCallback(() => {
    getUserBets()
      .then((res) => dispatch(addBets(res)))
      .catch((e) => console.log("error getUserBets", e));
  }, [dispatch]);
};

export default useUpdateGameBets;
