import "./slot.scss";
import "./style.sass";
import { useEffect, useState } from "react";
import { useTokenBalance } from "../../redux/token/hooks";
import {
  useGetAssetApiData,
  useGetUserInput,
} from "../../redux/application/hooks";
import { escapeRegExp } from "../../helpers/functions";
import TokenSelectorModal from "../../components/TokenSelectorModal";
import PlayButton from "./PlayButton";
import WoodenPlankComponent from "./WoodenPlankComponent";
import { useMediaQuery } from "react-responsive";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronDown, faEdit } from "@fortawesome/free-solid-svg-icons";

// @ts-ignore
import slotSound from "../../static/sound/slot-player.wav";
// @ts-ignore
import slotWin from "../../static/sound/slot-win.wav";
import { ICollateralType } from "../../config/games";

type IWinType = "win1" | "win2" | "";

// Mapping of indexes to icons: start from banana in middle of initial position and then upwards

// Height of the icons
// const icon_height = ((window_width - (box_wrapper_padding + slot_padding) - (chat_window_width + sidebar_width)) / 3 ) - (padding_between_reel * 3);
// Height of one icon in the strip
const icon_height = 120;
// Number of icons in the strip
const num_icons = 9;
// Max-speed in ms for animating one icon down
const time_per_icon = 100;
// Number of Columns
const no_columns: 4 | 3 = 4;

const Slot = () => {
  const userInput = useGetUserInput();
  const [input, setInput] = useState<string>(userInput.input);
  const [tokenSelectorModal, setTokenSelectorModal] = useState<boolean>(false);
  const [selectedToken, setSelectedToken] = useState<ICollateralType>(
    userInput.token,
  );
  const tokenBalance = useTokenBalance(selectedToken);

  const apiAssetData = useGetAssetApiData();
  const ethUsdValue = apiAssetData[selectedToken] || undefined;
  const betAmountInUsd = ethUsdValue
    ? Number(ethUsdValue) * Number(input)
    : undefined;

  const [winType, setWinType] = useState<IWinType>("");
  const [rolling, setRolling] = useState<boolean>(false);
  const reels = Array.from({ length: no_columns }, (v, i) => 0);
  // Holds icon indexes
  const [indexes, setIndexes] = useState<number[]>(reels);

  /**
   * Roll one reel
   */
  const roll = (reel: any, offset = 0) => {
    // Minimum of 2 + the reel offset rounds
    const delta =
      (offset + 2) * num_icons + Math.round(Math.random() * num_icons);

    // Return promise so we can wait for all reels to finish
    return new Promise((resolve, reject) => {
      const style = getComputedStyle(reel);
      // Current background position
      // @ts-ignore
      const backgroundPositionY = parseFloat(style["background-position-y"]);
      // Target background position
      const targetBackgroundPositionY =
        backgroundPositionY + delta * icon_height;
      // Normalized background position, for reset
      const normTargetBackgroundPositionY =
        targetBackgroundPositionY % (num_icons * icon_height);

      // Delay animation with timeout, for some reason a delay in the animation property causes stutter
      setTimeout(() => {
        // Set transition properties ==> https://cubic-bezier.com/#.41,-0.01,.63,1.09
        reel.style.transition = `background-position-y ${
          (8 + delta) * time_per_icon
        }ms cubic-bezier(.41,-0.01,.63,1.09)`;
        // Set background position
        reel.style.backgroundPositionY = `${
          backgroundPositionY + delta * icon_height
        }px`;
      }, offset * 150);

      // After animation
      setTimeout(
        () => {
          // Reset position, so that it doesn't get higher without limit
          reel.style.transition = `none`;
          reel.style.backgroundPositionY = `${normTargetBackgroundPositionY}px`;
          // Resolve this promise
          resolve(delta % num_icons);
        },
        (8 + delta) * time_per_icon + offset * 150,
      );
    });
  };

  /**
   * Roll all reels, when promise resolves roll again
   */
  function rollAll() {
    setTimeout(() => playSound(slotSound), 500);

    setRolling(true);

    const reelsList = document.querySelectorAll(".slots > .reel");

    // Activate each reel, must convert NodeList to Array for this with spread operator
    // @ts-ignore
    Promise.all([...reelsList].map((reel, i) => roll(reel, i)))

      // When all reels done animating (all promises solve)
      .then((deltas: any[]) => {
        const localIndexes = indexes;
        // add up indexes
        deltas.forEach((delta, i) => {
          localIndexes[i] = (localIndexes[i] + delta) % num_icons;
        });
        setIndexes([...indexes]);
        setRolling(false);
        winResult(localIndexes);
      });
  }

  const winResult = (localIndexes: number[]) => {
    // @ts-ignore
    if (no_columns === 3) {
      // Win conditions
      if (
        localIndexes[0] === localIndexes[1] ||
        localIndexes[1] === localIndexes[2]
      ) {
        playSound(slotWin);
        const winCls = localIndexes[0] === localIndexes[2] ? "win2" : "win1";
        setWinType(winCls);
        // slotsElement.classList.add(winCls);
        // setTimeout(() => slotsElement.classList.remove(winCls), 2000)
      }
    } else if (no_columns === 4) {
      if (
        localIndexes[0] === localIndexes[1] ||
        localIndexes[1] === localIndexes[2] ||
        localIndexes[2] === localIndexes[3]
      ) {
        playSound(slotWin);
        const winCls =
          localIndexes[0] === localIndexes[2] &&
          localIndexes[1] === localIndexes[4]
            ? "win2"
            : "win1";
        setWinType(winCls);
      }
    }
  };

  const enforcer = (nextUserInput: string) => {
    const inputRegex = RegExp(`^\\d*(?:\\\\[.])?\\d*$`);
    if (nextUserInput === "" || inputRegex.test(escapeRegExp(nextUserInput))) {
      setInput(nextUserInput);
      // setBetAmount(Math.max(Number(nextUserInput), 0.001).toString());
    }
  };

  const playSound = (url: string) => {
    const audio = new Audio(url);
    return audio.play();
  };

  useEffect(() => {
    if (winType !== "") {
      setTimeout(() => setWinType(""), 3000);
    }
  }, [winType]);

  const scale = window.innerWidth > 590 ? 1 : window.innerWidth / 590;

  const isBelow1240 = useMediaQuery({ maxWidth: "1440px" });

  return (
    <div id={"slot"}>
      {tokenSelectorModal && (
        <TokenSelectorModal
          open={tokenSelectorModal}
          handleClose={() => setTokenSelectorModal(!tokenSelectorModal)}
          selectedToken={selectedToken}
          setSelectedToken={(token) => {
            setTokenSelectorModal(false);
            setSelectedToken(token);
          }}
        />
      )}
      <div className={"playing-area"}>
        <section style={{ transform: `scale(${scale})` }}>
          <div className={winType !== "" ? `slots ${winType}` : "slots"}>
            {reels.map((i) => (
              <div className="reel"></div>
            ))}
          </div>
          <div className={"actions"}>
            <div className={"playing-area-row"}>
              <div className={"left-area"}>
                <WoodenPlankComponent label={"BET AMOUNT"}>
                  <div className={"amount-field"}>
                    <FontAwesomeIcon icon={faEdit} className={"plank-text"} />
                    <input
                      value={input}
                      onChange={(event) => enforcer(event.target.value)}
                      className={"input-field plank-text"}
                    />
                  </div>
                </WoodenPlankComponent>
                {!isBelow1240 && (
                  <WoodenPlankComponent label={"TOKEN"}>
                    <p
                      className={"plank-text"}
                      onClick={() => setTokenSelectorModal(true)}
                    >
                      {selectedToken} <FontAwesomeIcon icon={faChevronDown} />
                    </p>
                  </WoodenPlankComponent>
                )}
              </div>
              <div className={"cta-area"}>
                <PlayButton
                  tokenBalance={tokenBalance}
                  betAmount={input}
                  handlePlay={() => rollAll()}
                  loading={rolling}
                />
              </div>
              <div className={"right-area"}>
                <WoodenPlankComponent label={"BALANCE"}>
                  <p className={"plank-text"}>{tokenBalance.toFixed(4)}</p>
                </WoodenPlankComponent>
                {!isBelow1240 && (
                  <WoodenPlankComponent label={"YOUR WINS"}>
                    <p className={"plank-text"}>{10}</p>
                  </WoodenPlankComponent>
                )}
              </div>
            </div>
            {isBelow1240 && (
              <div className={"playing-area-row"}>
                <WoodenPlankComponent label={"BALANCE"}>
                  <p
                    className={"plank-text cursor"}
                    onClick={() => setTokenSelectorModal(true)}
                  >
                    {selectedToken} <FontAwesomeIcon icon={faChevronDown} />
                  </p>
                </WoodenPlankComponent>
                <WoodenPlankComponent label={"YOUR WINS"}>
                  <p className={"plank-text"}>{10}</p>
                </WoodenPlankComponent>
              </div>
            )}
          </div>
        </section>
      </div>
    </div>
  );
};

export default Slot;
