export function escapeRegExp(string: string): string {
  return string.replace(/[.*+?^${}()|[\]\\]/g, "\\$&");
}

export function getPopUpKey(): string {
  const randomNo = Math.floor(new Date().getTime());
  return randomNo.toString();
}

export const truncateMiddle = function (
  fullStr: string = "",
  strLen: number,
  separator?: string,
) {
  if (fullStr.length <= strLen) {
    return fullStr;
  }

  separator = separator || "...";

  var sepLen = separator.length,
    charsToShow = strLen - sepLen,
    frontChars = Math.ceil(charsToShow / 3),
    backChars = Math.floor(charsToShow / 3);

  return (
    fullStr.substr(0, frontChars) +
    separator +
    fullStr.substr(fullStr.length - backChars)
  );
};
