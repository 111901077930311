import { useMemo } from "react";
import { truncateMiddle } from "../../../helpers/functions";
import { IChat } from "../index";
import "./style.sass";

interface IProps {
  chatData: IChat;
}

const Comment = (props: IProps) => {
  const { chatData } = props;

  const color = useMemo(() => {
    const id = chatData.from.username.charCodeAt(0);

    const colors = ["#A38783", "#B43816", "#FF4E0C", "#E2B39F"];

    return colors[id % colors.length];
  }, [chatData.from.username]);

  return (
    <div className="comment-main">
      <span className="comment-name" style={{ color }}>
        {truncateMiddle(chatData.from.username, 14)}:
      </span>{" "}
      {chatData.message}
    </div>
  );
};

export default Comment;
