import WithdrawGlobal from "./withdrawGlobal";
import AccountHeader from "../../AccountHeader";

const Withdraw = () => {
  return (
    <div id={"withdraw-page"}>
      <AccountHeader title={"Withdraw Assets"} />
      <WithdrawGlobal />
    </div>
  );
};

export default Withdraw;
