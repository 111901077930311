import "./styles/component.css";
import "./styles/index.css";
import "./styles/style.css";

import { fas } from "@fortawesome/free-solid-svg-icons";
import { HashRouter as Router } from "react-router-dom";
import { library } from "@fortawesome/fontawesome-svg-core";
import { ProtocolProvider } from "./context/ProtocolProvider";
import { Provider, useDispatch } from "react-redux";
import { SnackbarProvider } from "notistack";
import { ThemeProvider } from "@mui/material";
import { toggleConfetti } from "./redux/application/actions";
import { useEffect } from "react";
import { useGetConfettiOpen } from "./redux/application/hooks";
import Navigation from "./Navigation";
import Popups from "./components/Popups";
import RainbowProvider from "./context/RainbowProvider";
import ServerProvider from "./context/ServerProvider";
import store from "./redux";
import theme from "./styles/theme";
import Tracking from "./components/Tracking";
import useCore from "./hooks/useCore";

library.add(fas);

const Providers = ({ children }: React.HTMLProps<HTMLBaseElement>) => {
  return (
    <ThemeProvider theme={theme}>
      <Provider store={store}>
        <Router>
          <ServerProvider>
            <RainbowProvider>
              <ProtocolProvider store={store}>
                <AppContent>{children}</AppContent>
              </ProtocolProvider>
            </RainbowProvider>
          </ServerProvider>
        </Router>
      </Provider>
    </ThemeProvider>
  );
};

const AppContent = ({ children }: React.HTMLProps<HTMLBaseElement>) => {
  const core = useCore();

  const open = useGetConfettiOpen();
  const dispatch = useDispatch();

  useEffect(() => {
    if (open) dispatch(toggleConfetti());
  }, [dispatch, open]);

  if (!core) return <div />;
  return (
    <SnackbarProvider
      anchorOrigin={{
        vertical: "top",
        horizontal: "center",
      }}
      maxSnack={2}
    >
      <>
        <Popups />
        {children}
      </>
    </SnackbarProvider>
  );
};

function App() {
  return (
    <Providers>
      <Tracking />
      <Navigation />
    </Providers>
  );
}

export default App;
