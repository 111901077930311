import { useEffect } from "react";
import { useLocation } from "react-router-dom";

import { Mixpanel } from "../../analytics/Mixpanel";
import { useGetUserId } from "../../redux/user/hooks";

const Tracking = () => {
  const location = useLocation();
  const userId = useGetUserId();

  useEffect(() => {
    Mixpanel.track(`${location.pathname}`, { type: "screen" });
  }, [location.pathname]);

  useEffect(() => {
    if (userId && userId.length > 0) {
      Mixpanel.identify(userId);
      Mixpanel.people.set({ userId });
    }
  }, [userId]);

  return null;
};

export default Tracking;
