import { core } from "../config";
import { Protocol } from "../protocol/Protocol";
import { Store } from "@reduxjs/toolkit";
import React, { createContext, useEffect, useState } from "react";
import SocketIO, { Socket } from "socket.io-client";

export interface ProtocolContext {
  core: Protocol;
  socket?: Socket;
}

export const Context = createContext<ProtocolContext>({
  core,
});

interface IProps {
  store: Store;
  children: React.ReactNode;
}

export const ProtocolProvider = (props: IProps) => {
  const { children } = props;

  const [socket, setSocket] = useState<Socket | undefined>();

  useEffect(() => {
    const host = core._config.relayerUrl
      .replace("http://", "ws://")
      .replace("https://", "wss://");

    const _socket = SocketIO(host, {
      transports: ["websocket", "xhr-polling"],
      // upgrade: true,
      autoConnect: true,
      reconnection: true,
    });

    _socket.on("connect", () => {
      console.log("connected to websocket");
    });

    _socket.on("reconnect_attempt", () => {
      console.log("socketio reconnecting");
    });

    _socket.on("connect_error", (error: any) => {
      console.log("socketio error", error);
    });

    _socket.on("disconnect", (reason: any) => {
      console.log("socketio disconenct; reconnecting", reason);
      _socket?.open();
    });

    _socket.open();

    setSocket(_socket);
  }, []);

  if (core)
    return (
      <Context.Provider value={{ core, socket }}>{children}</Context.Provider>
    );

  return <div />;
};
