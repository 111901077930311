export type ICollateralType = "ETH" | "USD" | "MCAKE" | "FUN";
export const diceGame = {
  id: "dice-game",
  name: "Dice Game",
  collateral: ["ETH", "USD", "MCAKE", "FUN"],
  url: "/dice-game",
  themeColor: "blue",
};

export const slotGame = {
  id: "slot-game-1",
  name: "Slot Game",
  collateral: "USDC",
  url: "/slot-game/usdc",
  themeColor: "gray",
};

export const Games = [diceGame, slotGame];
