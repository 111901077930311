import { ICollateralType } from "../../config/games";
import { useTokenBalance } from "../../redux/token/hooks";
import IconLoader from "../IconLoader";
import { useGetAssetApiData } from "../../redux/application/hooks";

interface IProps {
  index: number;
  selectedToken: ICollateralType;
  passingToken: ICollateralType;
  setSelectedToken: (data: ICollateralType) => void;
}

const TokenRow = (props: IProps) => {
  const tokenBalance = useTokenBalance(props.passingToken);

  const apiAssetData = useGetAssetApiData();
  const tokenUsdValue = apiAssetData[props.passingToken] || undefined;
  const balanceInUsd = tokenUsdValue
    ? Number(tokenUsdValue) * Number(tokenBalance)
    : undefined;

  return (
    <div
      key={props.index}
      className={`option-item ${
        props.selectedToken === props.passingToken ? "active" : ""
      }`}
      onClick={() => props.setSelectedToken(props.passingToken)}
    >
      <div className={"token-info"}>
        <IconLoader
          iconName={props.passingToken}
          iconType={"tokenSymbol"}
          width={28}
        />
        <span>{props.passingToken.toString()}</span>
      </div>
      <div className={"token-balance-info"}>
        <p className={"native"}>
          {tokenBalance.toFixed(4)}&nbsp;{props.passingToken}
        </p>
        <p className={"usd"}>${balanceInUsd ? balanceInUsd.toFixed(4) : "-"}</p>
      </div>
    </div>
  );
};

export default TokenRow;
