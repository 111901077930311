import { AbiCoder } from "ethers/lib/utils.js";
import { addBet } from "../redux/bets/actions";
import { generateSha256Hash, play } from "../helpers/ServerApi";
import { Mixpanel } from "../analytics/Mixpanel";
import { Protocol } from "./Protocol";
import { toggleConfetti } from "../redux/application/actions";
import EventEmitter from "events";

// import song from "../static/sound/dice-win.wav";

export type IWinLoss = {
  seedHash: string;
  guess: string;
  random?: string;
  input: string;
  prize?: string;
  won?: boolean;
};

export interface ISendWithPermitParams {
  seedHash: string;
  clientHash: string;
  input: number;
  guess: number;
  inputCurrency: string;
}

/**
 * A helper class that manages the browser nonce and sends tx's to the relayer in one request
 */
export class BetsQueue extends EventEmitter {
  dispatch: any = {};
  core: Protocol;
  seedHash?: string;

  constructor(core: Protocol, dispatch: any) {
    super();
    this.core = core;
    this.dispatch = dispatch;
  }

  public initHash = () => {
    generateSha256Hash().then((res) => {
      this.seedHash = res.shaHash;
    });
  };

  public getHash = async () => {
    if (this.seedHash) return this.seedHash;
    return generateSha256Hash().then((res) => {
      this.seedHash = res.shaHash;
      return this.seedHash;
    });
  };

  playSound = (url: string) => {
    const audio = new Audio(url);
    return audio.play();
  };

  public async processTransaction(params: ISendWithPermitParams) {
    const random = Math.floor(Math.random() * 100000000000);
    const encode = new AbiCoder();
    const seed = encode.encode(["uint256"], [random]);

    const hash = await this.getHash();
    const data: ISendWithPermitParams = {
      ...params,
      seedHash: hash,
      clientHash: seed.toString(),
    };

    play("dice-2d", data)
      .then(async (response) => {
        const win = response.won;
        this.dispatch(addBet(response));

        Mixpanel.track("place_bet", {
          type: "response",
          status: "success",
          win,
          data,
          random: response.random,
          response,
        });

        if (win) {
          // console.log("won");
          // this.playSound(song);
          this.dispatch(toggleConfetti());
        }
      })
      .catch((err) => {
        console.log("err", err);
        // todo show snackbar here

        Mixpanel.track("place_bet", {
          type: "catch",
          status: "failed",
          data,
        });
      })
      .finally(() => this.initHash());
  }
}
