import { configureStore } from "@reduxjs/toolkit";
import { load, save } from "redux-localstorage-simple";
import { createLogger } from "redux-logger";

import application from "./application/reducer";
import token, { TokenState } from "./token/reducer";
import user, { UserState } from "./user/reducer";
import bets, { IBetsState } from "./bets/reducer";
import { ToolkitStore } from "@reduxjs/toolkit/dist/configureStore";
import { ApplicationState } from "../helpers/interface";

const PERSISTED_KEYS: string[] = ["token", "application", "user"];

export type AppStore = ToolkitStore<{
  application: ApplicationState;

  token: TokenState;
  user: UserState;
  bets: IBetsState;
}>;

const store: AppStore = configureStore({
  reducer: {
    application,
    token,
    user,
    bets,
  },
  middleware: (getDefaultMiddleware) => {
    const res = getDefaultMiddleware({
      serializableCheck: false,
    }).concat(save({ states: PERSISTED_KEYS }));

    return false ? res : res.concat(createLogger({ collapsed: true }));
  },

  preloadedState: load({ states: PERSISTED_KEYS }),
});

export default store;

export type AppState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
