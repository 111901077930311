import { useCallback, useEffect, useState } from "react";
import { depositTxns } from "../../helpers/ServerApi";
import { BasicDepositTxnState } from "../../helpers/interface";
import {
  LOADING_DEFAULT_DepositTxn_STATE,
  NON_LOADING_DEFAULT_DepositTxn_STATE,
} from "../../helpers/constants";

const useGetUserDeposit = () => {
  const [state, setState] = useState<BasicDepositTxnState>(
    NON_LOADING_DEFAULT_DepositTxn_STATE,
  );

  const fetchValue = useCallback(async () => {
    await depositTxns()
      .then((res: any) => {
        if (!res) {
          setState(LOADING_DEFAULT_DepositTxn_STATE);
          return;
        }

        setState({ isLoading: false, value: res });
      })
      .catch(() => setState(NON_LOADING_DEFAULT_DepositTxn_STATE));
  }, []);

  useEffect(() => {
    fetchValue().catch((err) => {
      setState(NON_LOADING_DEFAULT_DepositTxn_STATE);
      console.error(`Failed to load all order the data: ${err.stack}`);
    });
  }, [fetchValue]);

  return state;
};

export default useGetUserDeposit;
