import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useEffect, useState } from "react";
import {
  useGetDepositAddress,
  useSetDepositAddress,
} from "../../../../../redux/application/hooks";
import Button from "../../../../../components/Button";
import moment from "moment";
import QRCode from "react-qr-code";
import useGetUserDeposit from "../../../../../hooks/user/useGetUserDepositTXs";
import * as ServerApi from "../../../../../helpers/ServerApi";
import copyText from "copy-to-clipboard";

const DepositGlobal = () => {
  const [copyAddress, setCopyAddress] = useState<boolean>(false);
  const [hideTransaction, setHideTransaction] = useState<boolean>(true);
  const getAddress = useGetDepositAddress();
  const getDepositTxns = useGetUserDeposit();
  const setDepositAddress = useSetDepositAddress();

  useEffect(() => {
    ServerApi.getDepositAddress().then((data) => {
      setDepositAddress(data.depositAddress);
    });
  }, [setDepositAddress]);

  useEffect(() => {
    if (copyAddress) {
      setTimeout(() => setCopyAddress(false), 2000);
    }
  }, [copyAddress]);

  return (
    <div className={"deposit-withdraw-layout box-wrapper"}>
      <div className={"header-area"}>
        <div />
        <p className={"title-info"}>DEPOSIT ETHEREUM</p>
      </div>
      <div className={"main-content"}>
        <div className={"text-part"}>
          <div className={"secondary-info"}>
            <p>
              Send the amount of Ethereum of your choice to the following
              address to receive the equivalent in Coins.
            </p>
          </div>
          <div className={"warning"}>
            <FontAwesomeIcon icon={["fas", "warning"]} />
            <p>
              Only deposit over the Ethereum network. Do not use BNB or BSC
              networks
            </p>
          </div>
          <div className={"warning"}>
            <FontAwesomeIcon icon={["fas", "warning"]} />
            <p>
              Do NOT send NFT's to this ETH deposit address. In order to recover
              NFTs deposited to this address an administrative fee will be
              charged.
            </p>
          </div>
        </div>
        <div className={"qr-part"}>
          <QRCode
            style={{ height: 150, width: 150 }}
            value={getAddress ? getAddress : ""}
            viewBox={`0 0 256 256`}
          />
        </div>
      </div>
      <div className={"input-area"}>
        <div className={"label"}>YOUR PERSONAL ETHEREUM DEPOSIT ADDRESS</div>
        <div className={"sub-input-area"}>
          <input className={"input-field"} value={getAddress} disabled={true} />
          <Button
            className={"copy-btn"}
            onClick={() => {
              setCopyAddress(true);
              copyText(getAddress);
            }}
          >
            {copyAddress ? "COPIED ADDRESS" : "COPY ADDRESS"}
          </Button>
        </div>
      </div>
      <div
        className={"view-transaction-btn"}
        onClick={() => setHideTransaction(!hideTransaction)}
      >
        {hideTransaction ? "View Transaction" : "Hide Transaction"}
      </div>
      {!hideTransaction && (
        <div className={"transaction-history"}>
          <table>
            <tbody>
              <tr>
                <th>Amount</th>
                <th>Date/Time</th>
                <th>Transaction</th>
              </tr>
            </tbody>
            <tbody>
              {getDepositTxns.value.map((item) => (
                <tr>
                  <td>{item.assetAmount}</td>
                  <td>{moment(item.createdAt).format("MMMM Do YYYY")}</td>
                  <td className={"view-link"}>
                    <a href={item.etherscanUrl}>view</a>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      )}
    </div>
  );
};

export default DepositGlobal;
