import Snackbar from "@mui/material/Snackbar";
import React, { useEffect } from "react";

import { PopupContent } from "../../helpers/interface";
import { useRemovePopup } from "../../redux/application/hooks";

interface TxButtonProps {
  notificationCount?: number;
  index?: number;
  open?: boolean;
  content?: PopupContent;
  handleCancel?: () => void;
  popUpKey: string;
}
const CustomizedSnackbars: React.FC<TxButtonProps> = ({
  popUpKey,
  open,
  content,
  handleCancel,
}) => {
  const [openSnackbar, setOpen] = React.useState(open);

  const isScucess = content?.txn?.txStatus === "successful";
  const isLoading = content?.txn?.txStatus === "in-progress";
  const isFailed =
    content?.txn?.txStatus === "not-successful" || content?.error?.message;
  const remove = useRemovePopup();

  useEffect(() => {
    setOpen(true);
  }, [isScucess, isLoading]);

  const handleClose = (event?: React.SyntheticEvent, reason?: string) => {
    if (reason === "clickaway") return;
    setOpen(false);
    remove(popUpKey);
    if (handleCancel) handleCancel();
  };

  // const SlideTransition = React.forwardRef(function Transition(
  //   props: TransitionProps & {
  //     children: React.ReactElement<any, any>;
  //   },
  //   ref: React.Ref<unknown>
  // ) {
  //   return <Slide direction="left" ref={ref} {...props} children={<div />} />;
  // });

  // function SlideTransition(props: TransitionProps) {
  //   return <Slide {...props} direction="left"/>;
  // }

  const SnackHeader = () => {
    if (isLoading) {
      return (
        <div className={"header"} style={{ background: "#FEC107" }}>
          {/*<div className="single-line-center-start">
            <IconLoader
              iconName={"Pending"}
              iconType={"status"}
              width={24}
              className="pointer m-r-12"
              onClick={handleClose}
            />
            Transaction Pending
          </div>
          <IconLoader
            iconName={"Cross"}
            width={24}
            className="pointer"
            onClick={handleClose}
          />*/}
        </div>
      );
    } else if (isScucess) {
      return (
        <div className={"header"} style={{ background: "#28A644" }}>
          {/*<div className="single-line-center-start">
            <IconLoader
              iconName={"Success"}
              iconType={"status"}
              width={24}
              className="pointer m-r-12"
              onClick={handleClose}
            />
            Transaction Successful
          </div>
          <IconLoader
            iconName={"Cross"}
            width={24}
            className="pointer"
            onClick={handleClose}
          />*/}
        </div>
      );
    } else if (isFailed) {
      return (
        <div className={"header"} style={{ background: "#dc3444" }}>
          {/*<div className="single-line-center-start">
            <IconLoader
              iconName={"Success"}
              iconType={"status"}
              width={24}
              className="pointer m-r-12"
              onClick={handleClose}
            />
            Transaction Successful
          </div>
          <IconLoader
            iconName={"Cross"}
            width={24}
            className="pointer"
            onClick={handleClose}
          />*/}
        </div>
      );
    } else {
      return (
        <div className={"header"} style={{ background: "#6457ff" }}>
          {/*<div className="single-line-center-start">
            <IconLoader
              iconName={"Alert"}
              iconType={"status"}
              width={24}
              className="pointer m-r-12"
              onClick={handleClose}
            />
            Transaction Failed
          </div>
          <IconLoader
            iconName={"Cross"}
            width={24}
            className="pointer"
            onClick={handleClose}
          />*/}
        </div>
      );
    }
  };

  const SnackBody = () => {
    return (
      <div className={"body"}>
        <p>
          {content?.txn
            ? content?.txn?.summary || ""
            : content?.error?.message || "Error Occured"}
        </p>
        {/* {config.etherscanUrl !== "" && content?.txn?.hash && (
          <a
            href={`${config.etherscanUrl}/tx/${content?.txn?.hash}`}
            rel="noopener noreferrer"
            target="_blank"
            className={"links"}
          >
            <p>{"View on Explorer"}</p>
            {/*<IconLoader
              iconName={"ArrowLink"}
              iconType={"arrow"}
              width={24}
              className="pointer m-l-2 "
              onClick={handleClose}
            />
          </a>
        )} */}
      </div>
    );
  };

  return (
    <div className={"snackbar"}>
      {openSnackbar && (
        <Snackbar
          open={openSnackbar}
          autoHideDuration={3000}
          // TransitionComponent={SlideTransition}
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-ignore
          onClose={handleClose}
          anchorOrigin={{ vertical: "top", horizontal: "right" }}
          className={"snackbar-root"}
        >
          <div className={"content"}>
            {SnackHeader()}
            {SnackBody()}
          </div>
        </Snackbar>
      )}
    </div>
  );
};

export default CustomizedSnackbars;
