import "./style.sass";
import { IChildrenProp } from "../helpers/interface";
import { useGetJWT, useGetUser } from "../redux/user/hooks";
import { useMediaQuery } from "react-responsive";
import AppFooter from "./components/AppFooter";
import AppHeader from "./components/AppHeader";
import ChatComponent from "./components/Chat";
import SideMenu from "./components/SideMenu";
import UsernameSetup from "./components/UsernameSetup";
import React from "react";
import IconLoader from "../components/IconLoader";

const UsernameCheckRouter = (props: IChildrenProp) => {
  const user = useGetUser();
  const jwt = useGetJWT();

  if (jwt && !user)
    return <span>Please wait... while we are fetching your data</span>;

  if (jwt && user?._id && !user?.username) return <UsernameSetup />;

  return <>{props.children}</>;
};

const Page = (props: IChildrenProp) => {
  const isMobile = useMediaQuery({ maxWidth: "1024px" });

  return (
    <div id={"page"}>
      <AppHeader />
      <div id={"page-content"}>
        <SideMenu />
        <div id="main">
          <div id="main-content">
            <div>
              <UsernameCheckRouter>{props.children}</UsernameCheckRouter>
            </div>
            {isMobile && (
              <div className={"socials"}>
                <p className={"title"}>Join Our Socials</p>
                <div>
                  <a href={"https://discord.mooncake.gg"}>
                    <IconLoader iconName={"Discord"} iconType={"socialMedia"} />
                  </a>
                  <a href={"https://twitter.com/mooncakefi/"}>
                    <IconLoader iconName={"Twitter"} iconType={"socialMedia"} />
                  </a>
                  <a href={"https://t.me/mooncakefi"}>
                    <IconLoader
                      iconName={"Telegram"}
                      iconType={"socialMedia"}
                    />
                  </a>
                </div>
              </div>
            )}
          </div>
        </div>
        {!isMobile && <ChatComponent />}
      </div>

      <AppFooter />
    </div>
  );
};

export default Page;
