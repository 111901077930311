import { core } from "../config";
import store from "../redux";
import axios, { AxiosError } from "axios";

interface IHttpError {
  success: false;
  errorName: "Error";
  error: string;
}

export class HttpError extends Error {
  status: number = 500;
  constructor(message?: string, status?: number) {
    super(message);
    if (status) this.status = status;
  }
}

async function apiCaller<T>(
  endpoint: string,
  method: string = "get",
  body?: any,
): Promise<T> {
  const url = `${core._config.relayerUrl}/${endpoint}`;

  const state = store.getState();

  const headers: any = {
    "content-type": "application/json",
    "Access-Control-Allow-Origin": "*",
  };

  if (state.user.jwt) headers["x-jwt"] = state.user.jwt;

  try {
    const ret = await axios<T | IHttpError>({
      url,
      headers,
      method,
      data: body,
      // credentials: "include",
      // redirect: "follow",
      // mode: "no-cors",
    });

    const err = ret.data as IHttpError;
    if (ret.status !== 200) throw new HttpError(err.error, ret.status);

    const res = ret.data as T;
    return res;
  } catch (e: any) {
    const err = e as AxiosError;
    throw new HttpError(err.message, err.response?.status || 500);
  }
}

export default apiCaller;
