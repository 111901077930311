import "./style.sass";
import { IModalProp } from "../../helpers/interface";
import LoginContent from "./LoginContent";
import Modal from "../Modal";

export type IType = "login" | "register";

interface IProps {
  type: IType;
}

const LoginRegisterModal = (props: IModalProp & IProps) => {
  return (
    <Modal
      open={props.open}
      handleClose={props.handleClose}
      noCloseButton={true}
    >
      <div className={"login-register-modal"}>
        <LoginContent />
      </div>
    </Modal>
  );
};

export default LoginRegisterModal;
