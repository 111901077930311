import React from "react";
import plankImg from "../../static/images/slot/wooden-plank.png";

interface IProps {
  children: React.ReactNode;
  label?: string;
}

const WoodenPlankComponent = (props: IProps) => {
  return (
    <div className={"wooden-plank-main"}>
      {props.label && <p className={"label"}>{props.label}</p>}
      <div className={"wooden-plank"}>
        <img src={plankImg} alt={"plank"} />
        <div className={"children"}>{props.children}</div>
      </div>
    </div>
  );
};

export default WoodenPlankComponent;
