import { createReducer } from "@reduxjs/toolkit";
import { INITIAL_APP_STATE as init } from "../../helpers/constants";

import {
  addPopup,
  removePopup,
  setReferredBy,
  toggleConfetti,
  toggleSettingsMenu,
  toggleWalletModal,
  updateBlockNumber,
  updatePopup,
  updateUserInput,
  setGameIDs,
  setDepositAddress,
  setAssetPrices,
} from "./actions";
import { Popup } from "../../helpers/interface";
import { getPopUpKey } from "../../helpers/functions";

export default createReducer(init, (builder) => {
  builder
    .addCase(updateBlockNumber, (state, action) => {
      const { chainId, blockNumber } = action.payload;
      if (typeof state.blockNumber[chainId] !== "number") {
        state.blockNumber[chainId] = blockNumber;
      } else {
        state.blockNumber[chainId] = Math.max(
          blockNumber,
          state.blockNumber[chainId],
        );
      }
    })
    .addCase(toggleWalletModal, (state) => {
      state.walletModalOpen = !state.walletModalOpen;
    })
    .addCase(toggleSettingsMenu, (state) => {
      state.settingsMenuOpen = !state.settingsMenuOpen;
    })
    .addCase(setReferredBy, (state, { payload: { refBy } }) => {
      state.referredBy = refBy;
    })

    .addCase(setDepositAddress, (state, { payload: { address } }) => {
      state.depositAddress = address;
    })
    .addCase(setAssetPrices, (state, { payload }) => {
      state.assetPrices = payload["collateral-prices"];
    })
    .addCase(setGameIDs, (state, { payload: { id } }) => {
      state.gameIDs.push(id);
    })
    .addCase(
      addPopup,
      (state, { payload: { content, key, removeAfterMs = 15000 } }) => {
        state.popupList = (
          key
            ? state.popupList.filter((popup) => popup.key !== key)
            : state.popupList
        ).concat([
          {
            key: key || getPopUpKey(),
            show: true,
            content,
            removeAfterMs,
          },
        ]);
      },
    )
    .addCase(updatePopup, (state, { payload: { content, key } }) => {
      const popupList = state.popupList;
      // state.popupList = popupList.filter(_p => _p.key !== key);
      const popupFiltered = popupList.filter((_p) => _p.key === key);
      if (popupFiltered.length === 1) {
        let popup: Popup = popupFiltered[0];
        popup = {
          key: popup.key,
          show: true,
          removeAfterMs: popup.removeAfterMs,
          content: {
            ...content,
          },
        };
        state.popupList = popupList.map((_p) => {
          if (_p.key === key) {
            return popup;
          } else return _p;
        });
      }
    })
    .addCase(removePopup, (state, { payload: { key } }) => {
      state.popupList.forEach((p) => {
        if (p.key === key) {
          p.show = false;
        }
      });
    })
    .addCase(toggleConfetti, (state) => {
      state.confettiOpen = !state.confettiOpen;
    })
    .addCase(updateUserInput, (state, action) => {
      const { guess, input, token } = action.payload;
      state.userInput = {
        guess: guess || state.userInput.guess || init.userInput.guess,
        input: input || state.userInput.input || init.userInput.input,
        token: token || state.userInput.token || init.userInput.token,
      };
    });
});
