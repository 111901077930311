import { useState } from "react";
import "./style.sass";
import DepositGlobal from "./depositGlobal";
import BuyCrypto from "./BuyCrypto";
import AccountHeader from "../../AccountHeader";

type IValue = "fiat" | "eth";
const Deposit = () => {
  const [value, setValue] = useState<IValue>("eth");

  return (
    <div id={"deposit-page"}>
      <AccountHeader title={"Deposit Assets"} />
      {/*<h2 className={'header'}>Deposit</h2>*/}
      {/*<span className="sub-header">
        There are many ways to deposit assets into your account.
      </span>*/}

      <div className={"ul-items"}>
        <div
          className={`li-item ${value === "eth" ? "selected" : ""}`}
          onClick={() => setValue("eth")}
        >
          Deposit with ETH
        </div>
        <div
          className={`li-item ${value === "fiat" ? "selected" : ""}`}
          onClick={() => setValue("fiat")}
        >
          Deposit using Debit/Credit Card
        </div>
      </div>

      {/*<div className={'tabs'}>
        <div className={`tab ${value === 'eth' && 'active'}`} onClick={() => setValue('eth')}>Deposit On Ethereum</div>
        <div className={`tab ${value === 'base' && 'active'}`} onClick={() => setValue('base')}>Deposit On Base</div>
        <div className={`tab disabled`}>Credit/Debit (Soon)</div>
        <div className={`tab disabled`}>Bitcoin (Soon)</div>
      </div>*/}
      <div className={"content"}>
        {value === "eth" && <DepositGlobal />}
        {value === "fiat" && <BuyCrypto />}
      </div>
    </div>
  );
};

export default Deposit;
