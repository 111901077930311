import React from "react";

import { useNavigate } from "react-router-dom";
import { getGameLogo } from "../../../helpers/logos";

const GameCards = () => {
  const navigate = useNavigate();

  return (
    <div className={"games-col"}>
      <div
        className={"game-card"}
        style={{ backgroundImage: `url(${getGameLogo('dice')})` }}
        onClick={() => {
          navigate("/games/dice");
        }}
      />
    </div>
  );
};

export default GameCards;
