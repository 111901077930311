import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useEffect, useState } from "react";
import Button from "../../../../../components/Button";

import moonpay from "../../../../../static/images/buyCrypto/moonpay.svg";
import paybis from "../../../../../static/images/buyCrypto/paybis.svg";
import chainbits from "../../../../../static/images/buyCrypto/chainBits.png";
import { useGetDepositAddress } from "../../../../../redux/application/hooks";

const BuyCrypto = () => {
  const [copyAddress, setCopyAddress] = useState<boolean>(false);
  const getAddress = useGetDepositAddress();

  useEffect(() => {
    if (copyAddress) {
      setTimeout(() => setCopyAddress(false), 2000);
    }
  }, [copyAddress]);

  return (
    <div className={"deposit-withdraw-layout box-wrapper"}>
      <div className={"header-area"}>
        <div />
        <p className={"title-info"}>BUY CRYPTO USING CARDS</p>
      </div>
      <div className={"main-content"}>
        <div className={"info-part"}>
          <div className={"secondary-info"}>
            <p>
              While we look for a reliable card payment processor, you can
              purchase crypto using your card via the recommended third-party
              services below. After purchasing the crypto, you can deposit it
              directly to your Mooncake account.
            </p>
          </div>
          <div className={"warning"}>
            <FontAwesomeIcon icon={["fas", "warning"]} />
            <p>Please ensure only ETH is send to the ETH network.</p>
          </div>
        </div>
      </div>
      <div className={"buy-crypto-options"}>
        <a
          href={"https://buy.moonpay.com"}
          className={"buy-crypto-option"}
          target={"_blank"}
          rel="noreferrer"
        >
          <img src={moonpay} alt={"moonpay"} />
        </a>
        <a
          href={"https://buy.chainbits.com"}
          className={"buy-crypto-option"}
          target={"_blank"}
          rel="noreferrer"
        >
          <img src={chainbits} alt={"chainbits"} />
        </a>
        <a
          href={"https://paybis.com"}
          className={"buy-crypto-option"}
          target={"_blank"}
          rel="noreferrer"
        >
          <img src={paybis} alt={"paybis"} />
        </a>
      </div>
      <div className={"input-area"}>
        <div className={"label"}>YOUR PERSONAL ETHEREUM DEPOSIT ADDRESS</div>
        <div className={"sub-input-area"}>
          <input className={"input-field"} value={getAddress} />
          <Button className={"copy-btn"} onClick={() => setCopyAddress(true)}>
            {copyAddress ? "COPIED ADDRESS" : "COPY ADDRESS"}
          </Button>
        </div>
      </div>
    </div>
  );
};

export default BuyCrypto;
