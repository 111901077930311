import "./bethistory.sass";
import { IBetResult } from "../../../helpers/interface";
import { useEffect, useMemo } from "react";
import { useGetAllBetsFor } from "../../../redux/bets/hooks";
import { useGetUserId } from "../../../redux/user/hooks";
import useUpdateGameBets from "../../../hooks/games/callbacks/useUpdateGameBets";

const BetsHistory = () => {
  const userId = useGetUserId();
  const bets = useGetAllBetsFor(userId);

  const updateBetsHistory = useUpdateGameBets();

  const getBetColor = (bet: IBetResult) => {
    let bgColor = "#FFFFFF80";
    let color = "#FFFFFF";

    if (bet.won) {
      bgColor = "#28A64470";
      color = "#e4e5e8";
    } else {
      bgColor = "rgb(40 42 59)";
      color = "#e4e5e8";
    }

    return { color, bgColor };
  };

  const sortedArray = useMemo(
    () =>
      Object.values(bets).sort(
        (a, b) =>
          new Date(b.createdAt).getTime() - new Date(a.createdAt).getTime(),
      ),
    [bets],
  );

  console.log("updated", sortedArray, sortedArray.length);

  useEffect(() => {
    updateBetsHistory();
  }, [updateBetsHistory]);

  if (sortedArray.length === 0) return <div />;

  return (
    <div className={"bets-history"}>
      {sortedArray.map((data) => {
        const betData = data;
        const _c = getBetColor(betData);

        const random = data.random ? Math.floor(Number(data.random)) : null;

        const guess =
          Number(betData.guess) > 100
            ? Math.floor(Number(betData.guess))
            : Number(betData.guess);

        const lt = (random || 0) < guess ? "<" : ">";
        const randomText = !!random || random === 0 ? `${random} ${lt} ` : "🕗";

        return (
          // eslint-disable-next-line jsx-a11y/anchor-is-valid
          <a
            href={"#"}
            target="_blank"
            key={data._id}
            className={"individual-bet"}
            style={{ background: _c.bgColor, color: _c.color }}
            rel="noreferrer"
          >
            <p className={"bet-content"}>
              <span>{randomText}</span> <b>{guess}</b>
            </p>
          </a>
        );
      })}
    </div>
  );
};

export default BetsHistory;
