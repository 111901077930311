import { isProduction, isDevnet } from "./env";
import { Protocol } from "../protocol/Protocol";
import localDevnet from "./local-devnet";
import production from "./production";
import staging from "./staging";
import store from "../redux";

export const config = isProduction
  ? production
  : isDevnet
  ? localDevnet
  : staging;

export const core = new Protocol(config, store);
