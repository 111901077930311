import { useCallback } from "react";
import { getMyReferrals } from "../../helpers/ServerApi";
import { useDispatch } from "react-redux";
import { setUserReferrals } from "../../redux/user/actions";

const useUpdateUserReferrals = () => {
  const dispatch = useDispatch();

  return useCallback(async () => {
    await getMyReferrals().then(({ referrals }) =>
      dispatch(setUserReferrals({ referrals })),
    );
  }, [dispatch]);
};

export default useUpdateUserReferrals;
