import Carousel from "react-material-ui-carousel";
import banner1 from "../../static/images/banners/F9cw31ZWoAA1RBN.jpeg";
import banner2 from "../../static/images/banners/F9cu6_KW8AA7cFP.jpeg";

const Banners = () => {
  return (
    <div className={"promotional-banners"}>
      <Carousel stopAutoPlayOnHover={true} indicators={false}>
        <section className={"carousel-custom-item"}>
          <img src={banner1} alt={"banner1"} />
        </section>
        <section className={"carousel-custom-item"}>
          <img src={banner2} alt="banner2" />
        </section>
      </Carousel>
    </div>
  );
};

export default Banners;
