import { useCallback, useEffect, useMemo, useState } from "react";
import { useGetUserReferralCodes } from "../../../../../redux/user/hooks";
import * as ServerAPI from "../../../../../helpers/ServerApi";
import Button from "../../../../../components/Button";
import ReferralCodeRow from "./ReferralCodeRow";
import useUpdateReferralCodes from "../../../../../hooks/update/useUpdateReferralCodes";

const ReferralCodeList = () => {
  const [code, setCode] = useState<string>("");
  const codeRegex = useMemo(() => /^([a-zA-Z0-9_-]){0,20}$/, []);
  const headers = [
    {
      key: "playerName",
      flex: 0.3,
      name: "Referral Code",
    },
    {
      key: "payout",
      flex: 0.3,
      name: "USD Earned",
    },
    // {
    //   key: "profit",
    //   flex: 0.3,
    //   name: "Referred Players",
    // },
    {
      key: "link",
      flex: 0.3,
      name: "Link",
    },
  ];

  const updateReferralCodes = useUpdateReferralCodes();
  const codes = useGetUserReferralCodes();

  const addCode = useCallback(async () => {
    await ServerAPI.addReferralCode({ referralCode: code }).then((res) => {
      updateReferralCodes();
      setCode("");
    });
  }, [code, updateReferralCodes]);

  // download all the referral codes
  useEffect(() => {
    updateReferralCodes();
  }, [updateReferralCodes]);

  const setReferralCode = (str: string) => {
    const validUsername = str.match(codeRegex);
    if (!str) setCode("");
    if (validUsername && str.length < 20) setCode(str);
  };

  const isValidCode = useMemo(
    () => !!code.match(codeRegex) && code.length > 3,
    [code, codeRegex],
  );

  return (
    <div className={"referral-code-section"}>
      <div className="box-wrapper">
        <div className="title">Your Referrals Codes</div>
        <div className="desc">
          Create referral codes that are unique to you. Share your referral link
          across various social media and get 10% of all the house's earnings
          everytime a referred user places a bet.
        </div>

        <div className="input-box">
          <input
            value={code}
            className="referral-input"
            placeholder="Enter your custom referral code here"
            onChange={(event) => setReferralCode(event.target.value)}
          />

          <Button
            disabled={!isValidCode}
            onClick={addCode}
            className="add-button"
          >
            Create Referral Code
          </Button>
        </div>
      </div>

      <div id={"referrals-table"}>
        <div className={"table-main"}>
          <div className={"table-head"}>
            {headers.map((data) => {
              return (
                <div
                  className={"table-head-li"}
                  key={data.key}
                  style={{ flex: data.flex }}
                >
                  {data.name}
                </div>
              );
            })}
          </div>
          <div className={"table-body"}>
            {codes.length === 0 ? (
              <p className={"info"}>
                No referrals code found create yours one now
              </p>
            ) : (
              codes.map((data, index) => (
                <ReferralCodeRow data={data} key={index} />
              ))
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default ReferralCodeList;
