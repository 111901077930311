import { useEffect } from "react";
import { useNavigate } from "react-router-dom";

const RedirectPage = () => {
  const navigation = useNavigate();
  useEffect(() => navigation("/"), [navigation]);
  return <div>redirecting...</div>;
};

export default RedirectPage;
