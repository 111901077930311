import mixpanel from "mixpanel-browser";
import { isProduction } from "../config/env";

if (isProduction) {
  mixpanel.init("2c42998d9a038f15e7c44960695c01f6");
} else {
  mixpanel.init("05fa6dab79cfc43ab0cc490f8831168e");
}

const env_check = true;

let actions = {
  identify: (id) => {
    mixpanel.identify(id);
  },
  alias: (id) => {
    mixpanel.alias(id);
  },
  track: (name, props) => {
    mixpanel.track(name, props);
  },
  people: {
    set: (props) => {
      if (env_check) mixpanel.people.set(props);
    },
  },
};

export let Mixpanel = actions;
