import { Mixpanel } from "../../analytics/Mixpanel";

export type tracking_ids =
  | "place_bet"
  | "wallet"
  | "fund_account"
  | "login"
  | "deposit"
  | "switch_network"
  | "withdraw"
  | "welcome_join_discord"
  | "get_started";

interface IProps {
  onClick: () => void;
  tracking_id?: tracking_ids;
  tracking_params?: any;
}

const Button = (
  props: React.ButtonHTMLAttributes<HTMLButtonElement> & IProps,
) => {
  return (
    <button
      {...props}
      onClick={() => {
        const params = {
          type: "button",
          ...props.tracking_params,
        };
        if (props.tracking_id) {
          Mixpanel.track(`${props.tracking_id.toLowerCase()}`, params);
        }
        props.onClick();
      }}
    ></button>
  );
};

export default Button;
