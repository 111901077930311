import { useState } from "react";
import ProvablyFairModal from "./components/ProvablyFairModal";

const Explain = () => {
  const [provablyFairModal, setProvablyFairModal] = useState<boolean>(false);

  return (
    <>
      <ProvablyFairModal
        open={provablyFairModal}
        handleClose={() => setProvablyFairModal(false)}
      />
      {/* <div id={"intro"} className={"box-wrapper"}>
        Welcome to <b>Mooncake Bets</b>. A fast, transparent and{" "}
        <span className={"link"} onClick={() => setProvablyFairModal(true)}>
          provably-fair
        </span>{" "}
          casino that is open to all. Everyone is treated fairly and
        has a fair chance of winning the various on-chain prizes.
      </div> */}
      <div id={"explain"} className={"box-wrapper"}>
        To play this game, all you have to do is choose a number from the slider
        above and place a bet. A random number is rolled and if it is higher
        than the bet you have placed, then you will win the reward. If not, then
        you will lose the amount you have wagered.
        <br />
        <br />
        The higher then number you choose, the greater the reward but also the
        greater the risk.
      </div>
    </>
  );
};

export default Explain;
