import "./style.sass";

import LeaderboardRows from "./components/LeaderboardRows";
import useGetLeaderBoard from "../../hooks/games/state/useGetLeaderBoard";

const Leaderboard = () => {
  // const [interval, setInterval] = useState<string>('');
  const leaderBoardData = useGetLeaderBoard();
  const headers = [
    {
      key: "betId",
      flex: 0.3,
      name: "Rank",
    },
    {
      key: "playerName",
      flex: 0.3,
      name: "Player",
    },
    {
      key: "payout",
      flex: 0.3,
      name: "USD Wagered",
    },
    {
      key: "profit",
      flex: 0.3,
      name: "USD Won",
    },
  ];

  return (
    <div id={"allbets"}>
      <h2 className="allbets-header">Airdrop</h2>
      <span className="allbets-sub-header">
        Get to the top of the leaderboard to earn rewards and boost your points.
        The more bets you place, the more your rank improves. The top 100
        players get various rewards.
      </span>
      <div>
        {/* <Sorter selectedInterval={(value) => setInterval(value)} /> */}
      </div>
      <div className={"table-main"}>
        <div className={"table-head"}>
          {headers.map((data) => {
            return (
              <div
                className={"table-head-li"}
                key={data.key}
                style={{ flex: data.flex }}
              >
                {data.name}
              </div>
            );
          })}
        </div>
        <div className={"table-body"}>
          {leaderBoardData.value.map((data, index) => {
            return <LeaderboardRows key={index} data={data} index={index} />;
          })}
        </div>
      </div>
    </div>
  );
};

export default Leaderboard;
