import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useNavigate } from "react-router-dom";
import { MenuItems } from "./AppHeader";
import { useMediaQuery } from "react-responsive";
import IconLoader from "../../components/IconLoader";

const SideMenu = () => {
  const navigate = useNavigate();
  const isMobile = useMediaQuery({ maxWidth: "1024px" });
  if (isMobile) return <div />;

  return (
    <div id={"side-menu"}>
      {MenuItems.filter((m) => m.show.includes("desktop")).map(
        (item, index) => (
          <div
            key={index}
            className={"cursor side-menu-item"}
            onClick={() => {
              navigate(item.redirect);
            }}
          >
            <FontAwesomeIcon icon={["fas", item.icon]} />
            <div className={"side-menu-text"}>{item.name}</div>
          </div>
        ),
      )}
      <div className={"socials"}>
        <p className={"title"}>Join Our Socials</p>
        <div>
          <a href={"https://discord.mooncake.gg"}>
            <IconLoader iconName={"Discord"} iconType={"socialMedia"} />
          </a>
          <a href={"https://twitter.com/mooncakefi/"}>
            <IconLoader iconName={"Twitter"} iconType={"socialMedia"} />
          </a>
          <a href={"https://t.me/mooncakefi"}>
            <IconLoader iconName={"Telegram"} iconType={"socialMedia"} />
          </a>
        </div>
      </div>
    </div>
  );
};

export default SideMenu;
