import Button from "../Button";
import { core } from "../../config";

import google from "../../static/socials/google.png";
import discord from "../../static/socials/discord.svg";
import twitter from "../../static/socials/twitter-logo.svg";

const LoginContent = () => {
  return (
    <div className={"body"}>
      <div className={"header"}>Login into Mooncake</div>
      <div className={"form"}>
        {/* <div className={"label-field"}>
          <div className={"label"}>
            Username <span className={"asterisk"}>*</span>
          </div>
          <div className={"input-area"}>
            <input
              value={username}
              onChange={(event) => setUsername(event.target.value)}
              className={"input-field"}
              placeholder={"* john_eth_19"}
            />
          </div>
        </div>
        <div className={"label-field"}>
          <div className={"label"}>
            Password <span className={"asterisk"}>*</span>
          </div>
          <div className={"input-area"}>
            <input
              value={password}
              onChange={(event) => setPassword(event.target.value)}
              className={"input-field"}
              placeholder={"**********"}
            />
          </div>
        </div>
        <div className={"primary-cta"}>
          <div className={"primary-btn"}>
            LOGIN
          </div>
        </div>
        <div className={"divider"}>
          <span>OR</span>
        </div>*/}
        <div className="intro">
          You can use any of the various social logins to login into Mooncake.
        </div>
        <div className={"other-option"}>
          <Button
            className={"web3-wallet google"}
            onClick={async () => {
              window.location.href = `${core._config.relayerUrl}/google/login`;
            }}
          >
            <img src={google} alt="google" />
            <span>Continue with Google</span>
          </Button>
          <Button
            className={"web3-wallet discord"}
            onClick={async () => {
              window.location.href = `${core._config.relayerUrl}/discord/login`;
            }}
          >
            <img src={discord} alt="discord" />
            <span>Continue with Discord</span>
          </Button>
          <Button
            className={"web3-wallet twitter"}
            onClick={async () => {
              window.location.href = `${core._config.relayerUrl}/twitter/login`;
            }}
          >
            <img src={twitter} alt="twitter" />
            <span>Continue with Twitter</span>
          </Button>
        </div>
        {/* <div className={"divider"}>
          <span>Dont have an account?</span>
        </div>
        <div className={"secondary-cta"}>
          <Button className={"primary-btn"} onClick={props.onRegisterClick}>
            REGISTER HERE
          </Button>
        </div> */}
      </div>
    </div>
  );
};

export default LoginContent;
