import { createAction } from "@reduxjs/toolkit";
import {
  IBalance,
  IMyReferral,
  IReferralCodes,
  IUser,
} from "../../helpers/interface";

export const updateUserDetails = createAction<{
  user: IUser;
}>("user/setUserDetails");

export const updateUserName = createAction<{
  username: string;
}>("user/updateUserName");

export const setBalances = createAction<{
  balances: IBalance[];
}>("user/setBalances");

export const setJWT = createAction<{ jwt: string }>("user/setJWT");

export const setUserReferralCodes = createAction<{ codes: IReferralCodes[] }>(
  "user/setUserReferralCodes",
);

export const setUserReferrals = createAction<{ referrals: IMyReferral[] }>(
  "user/setUserReferrals",
);

export const logout = createAction("user/logout");
