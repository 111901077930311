import { useActivePopups } from "../../redux/application/hooks";
import TransactionSnackbar from "./TransactionSnackbar";
import { Popup, PopupList } from "../../helpers/interface";

export default function Popups() {
  const activePopups: PopupList = useActivePopups();

  return (
    <>
      {activePopups.map((p: Popup, i: any) => (
        <TransactionSnackbar
          key={p.key}
          index={i}
          notificationCount={i + 1}
          open
          content={p.content}
          popUpKey={p.key}
        />
      ))}
    </>
  );
}
