import { useMemo } from "react";
import { useGetLoggedIn } from "../../../redux/user/hooks";
import { useNavigate } from "react-router-dom";
import AccountButton from "../../../components/AccountButton/AccountButton";
import Button from "../../../components/Button";

interface IProps {
  tokenBalance: number;
  diceValue: number;
  betAmount: string;
  handlePlay: () => void;
  loading: boolean;
}

const PlayButton = ({
  tokenBalance,
  handlePlay,
  loading,
  betAmount,
  diceValue,
}: IProps) => {
  const navigate = useNavigate();

  // To check if all pending orders + placing bets amount is greater than mETH balance > to avoid failures of tx;
  const isBalanceEnough = useMemo(() => tokenBalance > 0, [tokenBalance]);

  const isLoggedIn = useGetLoggedIn();
  const showLoginButton = !isLoggedIn;

  const showDepositButton = useMemo(
    () => tokenBalance < Number(betAmount) && !showLoginButton,
    [betAmount, tokenBalance, showLoginButton],
  );

  const showPlayButton = useMemo(
    () => !showDepositButton && !showLoginButton,
    [showLoginButton, showDepositButton],
  );

  return (
    <>
      {showDepositButton && (
        <div>
          <p className={"info"}>
            You don't have enough balance to place this bet. Add funds into your
            account, so that you can place bets and win prizes!
          </p>
          <Button
            tracking_id={"fund_account"}
            className={"primary-btn funding-needed"}
            onClick={() => navigate("/account/deposit")}
          >
            Fund Your Account
          </Button>
        </div>
      )}

      {showLoginButton && (
        <div>
          <AccountButton />
        </div>
      )}

      {showPlayButton && (
        <div>
          <Button
            className={"primary-btn"}
            onClick={handlePlay}
            tracking_id={"place_bet"}
            tracking_params={{
              guess: Number(diceValue),
              input: Number(betAmount),
              token: "ETH",
            }}
            disabled={!isBalanceEnough || loading}
          >
            {loading ? "PLACING BET" : "PLACE BET"}
          </Button>
        </div>
      )}
    </>
  );
};

export default PlayButton;
