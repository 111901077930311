import "@rainbow-me/rainbowkit/styles.css";

import { configureChains, createClient, mainnet, WagmiConfig } from "wagmi";
import {
  connectorsForWallets,
  darkTheme,
  RainbowKitProvider,
} from "@rainbow-me/rainbowkit";
import { publicProvider } from "wagmi/providers/public";

import {
  coinbaseWallet,
  injectedWallet,
  metaMaskWallet,
  rainbowWallet,
  walletConnectWallet,
} from "@rainbow-me/rainbowkit/wallets";

const RainbowProvider = ({ children }: React.HTMLProps<HTMLBaseElement>) => {
  const { chains, provider } = configureChains([mainnet], [publicProvider()]);

  // const { connectors } = getDefaultWallets({
  //   appName: "Mooncake Bets",
  //   projectId: "bed78e6db1d65502d73abbc8da9ee595",
  //   chains,
  // });

  const popularWallets = {
    groupName: "Popular",
    wallets: [
      injectedWallet({ chains }),
      rainbowWallet({ chains, projectId: "bed78e6db1d65502d73abbc8da9ee595" }),
      coinbaseWallet({ appName: "Mooncake Bets", chains }),
      metaMaskWallet({ chains, projectId: "bed78e6db1d65502d73abbc8da9ee595" }),
      walletConnectWallet({
        chains,
        projectId: "bed78e6db1d65502d73abbc8da9ee595",
      }),
    ],
  };

  const connectors = connectorsForWallets([popularWallets]);

  const wagmiClient = createClient({
    autoConnect: true,
    connectors,
    provider,
  });

  return (
    <WagmiConfig client={wagmiClient}>
      <RainbowKitProvider
        chains={chains}
        theme={darkTheme()}
        showRecentTransactions={true}
        coolMode={true}
      >
        {children}
      </RainbowKitProvider>
    </WagmiConfig>
  );
};

export default RainbowProvider;
