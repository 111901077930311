import LoginRegisterModal from "../LoginRegisterModal";
import { useState } from "react";
import Button from "../Button";
import "./style.sass";
import { useGetLoggedIn } from "../../redux/user/hooks";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useNavigate } from "react-router-dom";
import { useGetUserName } from "../../redux/user/hooks";

const AccountButton = () => {
  const [open, setOpen] = useState<boolean>(false);
  // const [type, setType] = useState<IType>('login');
  const isLoggedIn = useGetLoggedIn();
  const navigate = useNavigate();
  const userName = useGetUserName();

  if (!isLoggedIn)
    return (
      <div className={"account-button"}>
        <LoginRegisterModal
          open={open}
          handleClose={() => setOpen(false)}
          type={"login"}
        />
        <div className={"not-logged-in"}>
          <Button className={"login primary-btn"} onClick={() => setOpen(true)}>
            Login
          </Button>
        </div>
      </div>
    );
  else
    return (
      <div className={"account-button"}>
        <div className={"logged-in"}>
          <Button
            className={"primary-btn"}
            onClick={() => navigate("/account/profile")}
          >
            <FontAwesomeIcon icon={["fas", "user"]} /> <span>{userName}</span>
          </Button>
        </div>
      </div>
    );
};

export default AccountButton;
