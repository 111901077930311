import _ from "underscore";
import "./style.sass";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { getAllChat } from "../../helpers/ServerApi";
import { useCallback, useEffect, useRef, useState } from "react";
import {
  useGetJWT,
  useGetLoggedIn,
  useGetUserName,
} from "../../redux/user/hooks";
import Comment from "./Comment";
import useSocketIO from "../../hooks/useSocketIo";

export interface IChat {
  _id: string;
  message: string;
  from: {
    _id: string;
    username: string;
  };
  createdAt: Date;
}

const Chat = (props: { className?: string }) => {
  const [messages, setArray] = useState<IChat[]>([]);
  const [chatText, setChatText] = useState<string>("");
  const messagesEndRef = useRef<HTMLInputElement | null>(null);
  const isUsername = useGetUserName();

  const jwt = useGetJWT();
  const isLoggedIn = useGetLoggedIn();
  const socket = useSocketIO();

  useEffect(() => {
    getAllChat()
      .then((r: IChat[]) => {
        setArray(r.reverse());
        setTimeout(() => messagesEndRef.current?.scrollIntoView(), 500);
      })
      .catch(_.noop);
  }, []);

  useEffect(() => {
    socket?.on("new_message", (r: IChat) => {
      setArray((oldArray) => [...oldArray, r]);
    });
  }, [socket]);

  const sendMessageSocket = useCallback(() => {
    if (jwt) {
      const body = { message: chatText, token: jwt };
      console.log("sending chat", body, socket);
      socket?.emit("new_chat", body);
      setChatText("");
      setTimeout(
        () => messagesEndRef.current?.scrollIntoView({ behavior: "smooth" }),
        500,
      );
    }
  }, [chatText, jwt, socket]);

  const handleKeyDown = useCallback(
    (event: React.KeyboardEvent) => {
      if (event.key === "Enter") sendMessageSocket();
    },
    [sendMessageSocket],
  );

  return (
    <div id="chat" className={props.className}>
      <div className="chat-window">
        {messages
          .filter((m) => m && m.from)
          .map((m) => (
            <Comment chatData={m} key={m._id} />
          ))}
        <div ref={messagesEndRef} />
      </div>
      {isLoggedIn && isUsername && (
        <div className="chat-input">
          <input
            value={chatText}
            onChange={(event) => setChatText(event.target.value)}
            className={"chat-input-field"}
            onKeyDown={handleKeyDown}
            placeholder="Say something..."
          />
          <div
            className="cursor"
            onClick={() => {
              sendMessageSocket();
            }}
          >
            <FontAwesomeIcon icon={"arrow-circle-right"} size="2x" />
          </div>
        </div>
      )}
    </div>
  );
};

export default Chat;
