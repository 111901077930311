import { useSelector } from "react-redux";
import { AppState } from "../index";
import { BigNumber } from "ethers";
import { TokenState } from "./reducer";
import { useGetUserId } from "../user/hooks";

export function safeGetBalanceOf(
  state: TokenState,
  token: string,
  who?: string,
) {
  const bal = state.balanceOf[token];
  return bal && who && bal[who] ? bal[who] : 0;
}

export function safeGetTotalSupply(
  state: TokenState,
  chainId: number,
  token: string,
) {
  const bal = state.totalSupply[`${chainId}:${token}`];
  return bal
    ? BigNumber.from(bal).toHexString()
    : BigNumber.from(0).toHexString();
}

export function useTokenBalanceOf(token: string, who?: string) {
  return useSelector((state: AppState) => {
    return safeGetBalanceOf(state.token, token, who);
  });
}

export function useTokenTotalSupply(chainId: number, token: string) {
  return useSelector((state: AppState) => {
    return safeGetTotalSupply(state.token, chainId, token);
  });
}

export function useTokenBalance(token: string) {
  const userId = useGetUserId();
  return useTokenBalanceOf(token, userId);
}
