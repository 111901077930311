import { createAction } from "@reduxjs/toolkit";
import { IBetResult, ILiveFeedBets } from "../../helpers/interface";

export const addBet = createAction<IBetResult>("bets/addBet");

export const addBets = createAction<IBetResult[]>("bets/addBets");

export const updateTxOfBet = createAction<IBetResult>("bets/updateTxOfBet");

export const addLivefeedBets = createAction<ILiveFeedBets[]>(
  "bets/addLivefeedBets",
);
