import { useCallback } from "react";
import { getUserReferralCodes } from "../../helpers/ServerApi";
import { useDispatch } from "react-redux";
import { setUserReferralCodes } from "../../redux/user/actions";

const useUpdateReferralCodes = () => {
  const dispatch = useDispatch();

  return useCallback(async () => {
    await getUserReferralCodes().then((res) =>
      dispatch(setUserReferralCodes({ codes: res.codes })),
    );
  }, [dispatch]);
};

export default useUpdateReferralCodes;
