import TokenSelectorModal from "../TokenSelectorModal";
import { useState } from "react";
import IconLoader from "../IconLoader";

import "./style.sass";
import {
  useGetUserInput,
  useUpdateUserInputs,
} from "../../redux/application/hooks";

const GlobalTokenSelector = () => {
  const userInput = useGetUserInput();
  const [openModal, setOpenModal] = useState<boolean>(false);
  const updateUserInput = useUpdateUserInputs();

  return (
    <div className={"token-selector"}>
      <TokenSelectorModal
        open={openModal}
        handleClose={() => setOpenModal(false)}
        selectedToken={userInput.token}
        setSelectedToken={(data) => {
          updateUserInput(undefined, undefined, data);
          setOpenModal(false);
        }}
      />
      <div className={"token-info"} onClick={() => setOpenModal(true)}>
        <IconLoader
          iconName={userInput.token}
          iconType={"tokenSymbol"}
          width={22}
        />
        <span>{userInput.token}</span>
        <IconLoader iconName={"ArrowDown"} iconType={"arrow"} width={14} />
      </div>
    </div>
  );
};

export default GlobalTokenSelector;
