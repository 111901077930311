import { useGetUserName } from "../../../redux/user/hooks";

const Profile = () => {
  const username = useGetUserName();

  return (
    <div className={""}>
      <div className={"box-wrapper username"}>
        <div className={"avatar"}>
          <p>{username.charAt(0).toUpperCase()}</p>
        </div>
        <div className={"info"}>
          <p>{username}</p>
        </div>
      </div>
      {/* <div className={"get-started"}>
        <p className={"sub-heading"}>GET STARTED</p>
        <div className={"box-wrapper"}>
          <div className={"started-row box-wrapper-no-border"}>
            <div className={"info"}>
              Verify your email
            </div>
            <div className={"pending-status"}>
              <FontAwesomeIcon icon={["fas", "ticket"]} />
              <p>Pending</p>
            </div>
          </div>
          <div className={"started-row box-wrapper-no-border"}>
            <div className={"info"}>
              Deposit
            </div>
            <div className={"pending-status"}>
              <FontAwesomeIcon icon={["fas", "ticket"]} />
              <p>Pending</p>
            </div>
          </div>
          <div className={"started-row box-wrapper-no-border"}>
            <div className={"info"}>
              Place bets
            </div>
            <div className={"pending-status"}>
              <FontAwesomeIcon icon={["fas", "ticket"]} />
              <p>Pending</p>
            </div>
          </div>
        </div>
      </div>
      <div className={"link-account"}>
        <p className={"sub-heading"}>LINK ACCOUNT</p>
        <div className={"box-wrapper"}>
          <div className={"link-row box-wrapper-no-border"}>
            <div className={"info"}>
              Google
            </div>
            <div className={"pending-status"}>
              <FontAwesomeIcon icon={["fas", "ticket"]} />
              <p>Pending</p>
            </div>
          </div>
        </div>
      </div> */}
    </div>
  );
};

export default Profile;
