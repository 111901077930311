import { useState } from "react";
import Button from "../../../../../components/Button";
import { useGetAssetApiData } from "../../../../../redux/application/hooks";
import useWithdraw from "../../../../../hooks/user/callback/useWithdraw";
import { useTokenBalance } from "../../../../../redux/token/hooks";

const WithdrawGlobal = () => {
  const apiAssetData = useGetAssetApiData();
  const ethUsdValue = apiAssetData["WETH"] || apiAssetData["ETH"] || undefined;
  const tokenBalance = useTokenBalance("ETH");

  const [address, setAddress] = useState<string>("");
  const [withdrawAmount, setWithdrawAmount] = useState<string>("");

  const withDrawAmountInUsd = ethUsdValue
    ? Number(ethUsdValue) * Number(withdrawAmount)
    : undefined;

  const withdrawAction = useWithdraw(address, withdrawAmount);

  const handleWithdraw = () => {
    withdrawAction(
      () => {},
      () => {},
      (e) => {},
    );
  };

  return (
    <div className={"box-wrapper"}>
      <div className={"deposit-withdraw-layout"}>
        <div className={"header-area"}>
          <div />
          <p className={"title-info"}>WITHDRAW ETHEREUM</p>
        </div>
        <div className={"main-content"}>
          <div className={"secondary-info"}>
            <p>
              Please enter the Ethereum wallet address you wish to receive the
              funds on. Once confirmed, the withdrawal is usually processed
              within a few minutes.
            </p>
          </div>
        </div>
        <div className={"input-area"}>
          <div className={"label"}>RECEIVING ETHEREUM ADDRESS</div>
          <div className={"sub-input-area"}>
            <input
              className={"input-field"}
              placeholder={"Paste your ethereum address here"}
              value={address}
              onChange={(event) => setAddress(event.target.value)}
            />
          </div>
        </div>
        <div className={"amount-area"}>
          <div className={"label-field"}>
            <p className={"label"}>Withdraw Amount</p>
            <p className={"data"}>Balance: {tokenBalance.toFixed(4)} ETH</p>
          </div>
          <div className={"input-area"}>
            <input
              value={withdrawAmount}
              defaultValue={withdrawAmount}
              onChange={(event) => setWithdrawAmount(event.target.value)}
              className={"input-field"}
              placeholder="0"
            />
            <div className={"token-view"}>ETH</div>
          </div>
          <div className={"fiat-value"}>
            {withDrawAmountInUsd !== undefined
              ? `$${withDrawAmountInUsd.toFixed(2)}`
              : "-"}
          </div>
        </div>
        <div className={"cta-btn"}>
          <Button
            className={"primary-btn"}
            tracking_id={"withdraw"}
            tracking_params={{
              amount: withdrawAmount,
            }}
            onClick={handleWithdraw}
            disabled={!withdrawAmount || !tokenBalance}
          >
            {!tokenBalance ? "Insufficient balance" : "WITHDRAW"}
          </Button>
        </div>
      </div>
    </div>
  );
};

export default WithdrawGlobal;
