import { useEffect, useState } from "react";
import { IReferralCodes } from "../../../../../helpers/interface";
import copyText from "copy-to-clipboard";

const ReferralCodeRow = ({ data }: { data: IReferralCodes }) => {
  const [isCopied, setIsCopied] = useState<boolean>(false);

  useEffect(() => {
    if (isCopied) {
      const hide = setTimeout(() => setIsCopied(false), 1000);
      return () => clearTimeout(hide);
    }
  }, [isCopied, setIsCopied]);

  const refLink = `https://${window.location.host}/#/?ref=${data.code}`;

  return (
    <div className={"table-row"}>
      <div className={"table-row-li"} style={{ flex: 0.3 }}>
        {data.code}
      </div>
      <div className={"table-row-li"} style={{ flex: 0.3 }}>
        ${(data.usdEarned || 0).toFixed(2)}
      </div>
      {/* <div className={"table-row-li"} style={{ flex: 0.3 }}>{0}</div> */}
      <div
        className={"table-row-li"}
        style={{ flex: 0.3 }}
        onClick={() => {
          setIsCopied(true);
          copyText(refLink);
        }}
      >
        {isCopied ? "Copied" : "Copy"}
      </div>
    </div>
  );
};

export default ReferralCodeRow;
