import { useCallback, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import { PopupContent } from "../../helpers/interface";
import { AppState } from "../index";

import {
  addPopup,
  removePopup,
  setReferredBy,
  toggleConfetti,
  toggleSettingsMenu,
  toggleWalletModal,
  updatePopup,
  updateUserInput,
  setGameIDs,
  setDepositAddress,
} from "./actions";
import { ICollateralType } from "../../config/games";

export function useWalletModalOpen(): boolean {
  return useSelector((state: AppState) => state.application.walletModalOpen);
}

export function useWalletModalToggle(): () => void {
  const dispatch = useDispatch();
  return useCallback(() => dispatch(toggleWalletModal()), [dispatch]);
}

export function useSettingsMenuOpen(): boolean {
  return useSelector((state: AppState) => state.application.settingsMenuOpen);
}

export function useGetUserInput(): {
  input: string;
  guess: number;
  token: ICollateralType;
} {
  const userInput = useSelector(
    (state: AppState) => state.application.userInput,
  );

  return {
    guess: userInput.guess || 50,
    input: userInput.input || "0.01",
    token: userInput.token || "ETH",
  };
}

export function useGetReferredBy(): string {
  return useSelector((state: AppState) => state.application.referredBy);
}

export function useGetDepositAddress(): string {
  return useSelector((state: AppState) => state.application.depositAddress);
}
export function useToggleSettingsMenu(): () => void {
  const dispatch = useDispatch();
  return useCallback(() => dispatch(toggleSettingsMenu()), [dispatch]);
}

export function useSetReferredBy(): (refBy: string) => void {
  const dispatch = useDispatch();
  return useCallback((refBy) => dispatch(setReferredBy({ refBy })), [dispatch]);
}

export function useSetDepositAddress(): (address: string) => void {
  const dispatch = useDispatch();
  return useCallback(
    (address) => dispatch(setDepositAddress({ address })),
    [dispatch],
  );
}

export function useAddGameIds(): (id: string) => void {
  const dispatch = useDispatch();
  return useCallback((id) => dispatch(setGameIDs({ id })), [dispatch]);
}

// Returns a function that allows adding a popup.
export function useAddPopup(): (content: PopupContent, key?: string) => void {
  const dispatch = useDispatch();

  return useCallback(
    (content: PopupContent, key?: string) => {
      dispatch(addPopup({ content, key }));
    },
    [dispatch],
  );
}

// Returns a function that allows adding a popup.
export function useUpdateUserInputs(): (
  guess?: number,
  input?: string,
  token?: ICollateralType,
) => void {
  const dispatch = useDispatch();

  return useCallback(
    (guess?: number, input?: string, token?: ICollateralType) => {
      dispatch(updateUserInput({ guess, input, token }));
    },
    [dispatch],
  );
}

export function useUpdateTxPopup(): (
  content: PopupContent,
  key: string,
) => void {
  const dispatch = useDispatch();

  return useCallback(
    (content: PopupContent, key: string) => {
      dispatch(updatePopup({ content, key }));
    },
    [dispatch],
  );
}

// Returns a function that allows removing a popup via its key.
export function useRemovePopup(): (key: string) => void {
  const dispatch = useDispatch();
  return useCallback(
    (key: string) => {
      dispatch(removePopup({ key }));
    },
    [dispatch],
  );
}

// Get the list of active popups.
export function useActivePopups(): AppState["application"]["popupList"] {
  const list = useSelector((state: AppState) => state.application.popupList);
  return useMemo(() => list.filter((item) => item.show), [list]);
}

export function useConfettiToggle(): () => void {
  const dispatch = useDispatch();
  return useCallback(() => dispatch(toggleConfetti()), [dispatch]);
}

export function useGetConfettiOpen(): boolean {
  return useSelector((state: AppState) => state.application.confettiOpen);
}

export function useGetAssetApiData() {
  return useSelector((state: AppState) => state.application.assetPrices);
}
