import "./style.sass";
import { diceGame } from "../../../../config/games";
import { useGetAssetApiData } from "../../../../redux/application/hooks";
import { useMemo } from "react";
import { useNavigate } from "react-router-dom";
import { useTokenBalance } from "../../../../redux/token/hooks";
import AccountHeader from "../../AccountHeader";
import BalanceRow from "./BalanceRow";
import Button from "../../../../components/Button";
import store from "../../../../redux";
import { useGetUserId } from "../../../../redux/user/hooks";

const Balance = () => {
  const apiAssetData = useGetAssetApiData();
  const navigate = useNavigate();
  const tokenBalance = useTokenBalance("MCAKE");

  const userID = useGetUserId();
  const allBalance = store.getState().token.balanceOf;

  const totalUSDValue = useMemo(() => {
    let sum = 0;

    diceGame.collateral.forEach((data) => {
      const symbol = data;
      const balance = allBalance[symbol]
        ? allBalance[symbol][userID]
          ? allBalance[symbol][userID]
          : 0
        : 0;
      const usdValue = apiAssetData[symbol] || undefined;
      const balanceInUsd = usdValue
        ? Number(usdValue) * Number(balance)
        : undefined;

      if (balanceInUsd) sum += balanceInUsd;
    });

    return sum;
  }, [allBalance, apiAssetData, userID]);

  return (
    <div className={"balance-page"}>
      <AccountHeader title={"Your Wallet Balance"} />
      <div className={"balance-info-data box-wrapper"}>
        <div className={"info-part"}>
          <div className={"info-row"}>
            <div className={"info-data"}>
              <p className={"info-title"}>MCAKE</p>
              <p className={"info-value"}>{tokenBalance.toFixed(3)}</p>
            </div>
            <div className={"info-data"}>
              <p className={"info-title"}>Total Balance</p>
              <p className={"info-value"}>${totalUSDValue.toFixed(4)}</p>
            </div>
          </div>
          <div className={"claim-button"}>
            <Button
              onClick={() => navigate("/account/deposit")}
              disabled={false}
            >
              DEPOSIT
            </Button>
          </div>
        </div>
      </div>
      <BalanceRow
        description="The native currency of the Ethereum blockchain"
        symbol={"ETH"}
      />
      <BalanceRow
        description="Cashback earned from placing bets"
        symbol={"USD"}
      />
      <BalanceRow
        description="Incentives earned from interacting with Mooncake"
        symbol={"MCAKE"}
      />
      <BalanceRow
        description="Trial balance for users to play with"
        symbol={"FUN"}
      />
    </div>
  );
};

export default Balance;
