import { useCallback } from "react";
import { getDollarValues } from "../../helpers/ServerApi";
import { useDispatch } from "react-redux";
import { setAssetPrices } from "../../redux/application/actions";

const useUpdateAssetState = () => {
  const dispatch = useDispatch();

  return useCallback(async () => {
    await getDollarValues().then((res) => dispatch(setAssetPrices(res)));
  }, [dispatch]);
};

export default useUpdateAssetState;
