interface IProps {
  title: string;
}

const AccountHeader = (props: IProps) => {
  return (
    <div className={"account-header"}>
      <p className={"title"}>{props.title.toUpperCase()}</p>
    </div>
  );
};

export default AccountHeader;
