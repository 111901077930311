import IconLoader from "../../../../components/IconLoader";
import { useGetAssetApiData } from "../../../../redux/application/hooks";
import { useTokenBalance } from "../../../../redux/token/hooks";
import { useGetUserBalanceOf } from "../../../../redux/user/hooks";

interface IProps {
  symbol: string;
  description: string;
}

const BalanceRow = (props: IProps) => {
  const apiAssetData = useGetAssetApiData();
  const tokenBalance = useTokenBalance(props.symbol);
  const usdValue = apiAssetData[props.symbol] || undefined;
  const balanceInUsd = usdValue
    ? Number(usdValue) * Number(tokenBalance)
    : undefined;
  const balObj = useGetUserBalanceOf(props.symbol);

  return (
    <div className={"all-balances-data box-wrapper"}>
      <div className={"balance-info-row"}>
        <div className={"left-part"}>
          <div className={"token-icon"}>
            <IconLoader
              iconName={props.symbol}
              iconType={"tokenSymbol"}
              width={36}
            />
          </div>
          <div className={"info-part"}>
            <p className={"label"}>{props.symbol} Balance</p>
            <p className={"data"}>
              {tokenBalance.toFixed(4)} {props.symbol} &middot; $
              {balanceInUsd !== undefined ? balanceInUsd.toFixed(2) : "-"}
            </p>
            <p className={"desc"}>{props.description}</p>
          </div>
        </div>
        <div className={"action-area"}>
          <p
            className={balObj && balObj.usdWagered < 500 ? "disabled" : ""}
            onClick={() => {}}
          >
            {props.symbol === "USD" ? "Convert to $MCAKE" : ""}
          </p>
        </div>
      </div>
    </div>
  );
};

export default BalanceRow;
