import { AppState } from "../../redux";
import { useConfettiToggle } from "../../redux/application/hooks";
import { useSelector } from "react-redux";
import BetsHistory from "./components/BetsHistory";
import Confetti from "react-confetti";
import PlayingArea from "./PlayingArea";
import Explain from "./Explain";

import "./style.sass";

const Dice = () => {
  const showConfetti = useSelector(
    (state: AppState) => state.application.confettiOpen,
  );
  const toggleConfetti = useConfettiToggle();

  return (
    <>
      {showConfetti && (
        <Confetti
          run={true}
          onConfettiComplete={() => toggleConfetti()}
          recycle={false}
        />
      )}
      <Explain />
      <BetsHistory />
      <PlayingArea />
    </>
  );
};

export default Dice;
