import "./style.sass";
import { diceGame } from "../config/games";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useGetAssetApiData } from "../redux/application/hooks";
import { useMemo, useState } from "react";
import Button from "./Button";
import store from "../redux";
import TransferTypeModal from "../modules/dice/components/TransferTypeModal";
import { useGetUserId } from "../redux/user/hooks";

const BalanceButton = () => {
  const [depositModal, setDepositModal] = useState<boolean>(false);

  const apiAssetData = useGetAssetApiData();
  const userID = useGetUserId();
  const allBalance = store.getState().token.balanceOf;

  const totalUSDValue = useMemo(() => {
    let sum = 0;

    diceGame.collateral.forEach((data) => {
      const symbol = data;
      const balance = allBalance[symbol]
        ? allBalance[symbol][userID]
          ? allBalance[symbol][userID]
          : 0
        : 0;

      const usdValue = apiAssetData[symbol] || 0;
      const balanceInUsd = usdValue
        ? Number(usdValue) * Number(balance)
        : undefined;

      if (balanceInUsd) sum += balanceInUsd;
    });

    return sum;
  }, [allBalance, apiAssetData, userID]);

  return (
    <div className={"balance-button"}>
      {depositModal && (
        <TransferTypeModal
          open={depositModal}
          handleClose={() => setDepositModal(false)}
        />
      )}
      <Button
        id="balance-button"
        className={"cursor"}
        onClick={() => setDepositModal(true)}
        tracking_id={"wallet"}
      >
        <span>
          <FontAwesomeIcon icon={["fas", "wallet"]} />
          &nbsp;&nbsp;&nbsp; ${totalUSDValue.toFixed(2)}
        </span>
      </Button>
    </div>
  );
};

export default BalanceButton;
