const Intro = () => {
  return (
    <>
      {/* <div id={"intro"} className={"box-wrapper"}>
        Welcome to <b>Mooncake Bets</b>. A fast, transparent and{" "}
        <span className={"link"} onClick={() => setProvablyFairModal(true)}>
          provably-fair
        </span>{" "}
          casino that is open to all. Everyone is treated fairly and
        has a fair chance of winning the various on-chain prizes.
      </div> */}
      <div id={"intro"} className={"box-wrapper"}>
        Welcome to <b>Mooncake Bets</b>! A fast, provably-fair, KYC-free casino
        that is open to all. Everyone is treated fairly and has a fair chance of
        winning the various on-chain prizes. To learn more about how Mooncake
        works, visit our{" "}
        <a href="https://docs.mooncake.gg/" target="_blank" rel="noreferrer">
          documentation
        </a>
        .
      </div>

      {/*<div id={'prizes'} className={'box-wrapper'}>
        <p>
          Roll 5 consequetive wins to win a lucky <b>100,000$</b> jackpot. Enable the <b>"Jackpot-mode"</b> to become eligible.
          Each roll becomes harder on every win.
        </p>
      </div>*/}
    </>
  );
};

export default Intro;
