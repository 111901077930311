import { useDispatch, useSelector } from "react-redux";
import { AppState } from "../index";
import { useCallback } from "react";
import { setJWT, updateUserName } from "./actions";
import { IBalance, IMyReferral, IReferralCodes } from "../../helpers/interface";

export function useSetUserName(): (username: string) => void {
  const dispatch = useDispatch();
  return useCallback(
    (username) => dispatch(updateUserName({ username })),
    [dispatch],
  );
}

export function useGetUser() {
  return useSelector((state: AppState) => state.user.user);
}

export function useGetUserName(): string {
  return useSelector((state: AppState) => state.user.user?.username || "");
}

export function useGetUserId(): string {
  return useSelector((state: AppState) => state.user.user?._id || "");
}

export function useGetUserReferralCodes(): IReferralCodes[] {
  return useSelector((state: AppState) => state.user.codes || []);
}

export function useGetUserReferrals(): IMyReferral[] {
  return useSelector((state: AppState) => state.user.referrals || []);
}

export function useGetUserEmail(): string {
  return useSelector((state: AppState) => state.user.user?.email || "");
}

export function useGetJWT(): string | undefined {
  return useSelector((state: AppState) => state.user.jwt);
}

export function useGetLoggedIn(): boolean {
  return useSelector(
    (state: AppState) => !!state.user.user && !!state.user.jwt,
  );
}

export function useSetJWT(): (jwt: string) => void {
  const dispatch = useDispatch();
  return useCallback((jwt) => dispatch(setJWT({ jwt })), [dispatch]);
}

export function useGetUserBalanceOf(symbol: string): IBalance | undefined {
  const balances = useSelector((state: AppState) => state.user.balances);
  const [symbolBalance] = balances.filter(
    (bal) => symbol === bal.currency.symbol,
  );
  return symbolBalance;
}
