import { createAction } from "@reduxjs/toolkit";

import { IAssetPrices, PopupContent } from "../../helpers/interface";
import { ICollateralType } from "../../config/games";

export const updateBlockNumber = createAction<{
  chainId: number;
  blockNumber: number;
}>("app/updateBlockNumber");

export const updateUserInput = createAction<{
  guess?: number;
  input?: string;
  token?: ICollateralType;
}>("app/updateUserInput");

export const toggleWalletModal = createAction<void>("app/toggleWalletModal");

export const toggleSettingsMenu = createAction<void>("app/toggleSettingsMenu");

export const setReferredBy = createAction<{ refBy: string }>(
  "app/setReferredBy",
);

export const setDepositAddress = createAction<{ address: string }>(
  "app/setDepositAddress",
);

export const setGameIDs = createAction<{ id: string }>("app/setGameId");

export const setAssetPrices = createAction<IAssetPrices>("app/setAssetPrices");

export const addPopup = createAction<{
  key?: string;
  removeAfterMs?: number | null;
  content: PopupContent;
}>("app/addPopup");

export const updatePopup = createAction<{
  key: string;
  content: PopupContent;
}>("app/updatePopup");

export const removePopup = createAction<{ key: string }>("app/removePopup");

export const toggleConfetti = createAction<void>("app/toggleConfetti");
