import { useCallback } from "react";

import { withdrawal } from "../../../helpers/ServerApi";
import { useAddPopup } from "../../../redux/application/hooks";

export default function useWithdraw(address: string, withdrawAmount: string) {
  const addPopup = useAddPopup();

  return useCallback(
    async (
      onInitiating: () => void,
      onSuccess: () => void,
      onFailure: (e: string) => void,
    ): Promise<void> => {
      try {
        onInitiating();
        withdrawal({
          to: address,
          currency: "ETH",
          amount: Number(withdrawAmount),
        })
          .then((res) => {
            onSuccess();
            addPopup({
              txn: {
                txStatus: "in-progress",
                summary:
                  "We have received your withdrawal request. The process would take around 5-10 min to finish",
              },
            });
          })
          .catch((e) => {
            addPopup({
              error: {
                message: "Some error occurred. Please try again later",
                stack: "",
              },
            });
          });
      } catch (e: any) {
        onFailure(e);
        addPopup({
          error: {
            message: "Withdrawal Request Failed. Please try again later",
            stack: "",
          },
        });
      }
    },
    [addPopup, address, withdrawAmount],
  );
}
