import { useMediaQuery } from "react-responsive";
import { MenuItems } from "./AppHeader";
import { NavLink } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import "./app-footer.sass";
import { useGetUserName } from "../../redux/user/hooks";

const AppFooter = () => {
  const isMobile = useMediaQuery({ maxWidth: "1024px" });
  const isUsername = useGetUserName();

  if (!isMobile) return <div />;

  return (
    <div id={"app-footer"}>
      <div className={"menu-ul"}>
        {MenuItems.filter((m) => m.show.includes("mobile")).map(
          (item, index) => {
            return (
              <NavLink
                className={({ isActive }) =>
                  isActive ? "active menu-li" : "menu-li"
                }
                key={index}
                to={isUsername ? item.redirect : ""}
              >
                <FontAwesomeIcon icon={["fas", item.icon]} />
                <span>{item.name}</span>
              </NavLink>
            );
          },
        )}
      </div>
    </div>
  );
};

export default AppFooter;
