import { createReducer } from "@reduxjs/toolkit";
import {
  logout,
  setBalances,
  setJWT,
  setUserReferralCodes,
  setUserReferrals,
  updateUserDetails,
  updateUserName,
} from "./actions";

import {
  IBalance,
  IMyReferral,
  IReferralCodes,
  IUser,
} from "../../helpers/interface";

export interface UserState {
  jwt?: string;
  user?: IUser;
  codes: IReferralCodes[];
  referrals: IMyReferral[];
  balances: IBalance[];
}

export const initialState: UserState = {
  codes: [],
  referrals: [],
  balances: [],
};

export default createReducer(initialState, (builder) =>
  builder
    .addCase(updateUserDetails, (state, action) => {
      state.user = action.payload.user;
    })
    .addCase(logout, (state) => {
      delete state.user;
      delete state.jwt;
      state.codes = [];
      state.referrals = [];
    })
    .addCase(setJWT, (state, { payload: { jwt } }) => {
      state.jwt = jwt;
    })
    .addCase(setUserReferralCodes, (state, { payload: { codes } }) => {
      state.codes = codes || [];
    })
    .addCase(setUserReferrals, (state, { payload: { referrals } }) => {
      state.referrals = referrals || [];
    })
    .addCase(updateUserName, (state, action) => {
      const { username } = action.payload;
      if (state.user) state.user.username = username;
    })
    .addCase(setBalances, (state, { payload: { balances } }) => {
      state.balances = balances;
    }),
);
