import { useCallback } from "react";
import { getCurrentUser } from "../../helpers/ServerApi";
import { useDispatch } from "react-redux";
import { updateUserDetails } from "../../redux/user/actions";
import { logout } from "../../redux/user/actions";
import { HttpError } from "../../helpers/apiCaller";

const useUpdateUser = () => {
  const dispatch = useDispatch();

  return useCallback(async () => {
    await getCurrentUser()
      .then((res) => {
        if (res.user) dispatch(updateUserDetails({ user: res.user }));
      })
      .catch((err: HttpError) => {
        if (err.status === 401) dispatch(logout());
      });
  }, [dispatch]);
};

export default useUpdateUser;
