import { createReducer } from "@reduxjs/toolkit";
import { addBet, addBets, addLivefeedBets } from "./actions";
import { IBetResult, ILiveFeedBets } from "../../helpers/interface";

export interface IBetsState {
  bets: {
    [who: string]: {
      [_id: string]: IBetResult;
    };
  };

  liveFeed: ILiveFeedBets[];
}

export const initialState: IBetsState = { bets: {}, liveFeed: [] };

export default createReducer(initialState, (builder) =>
  builder
    .addCase(addBet, (_b, { payload }) => {
      _b.bets[payload.user] = _b.bets[payload.user] || {};
      _b.bets[payload.user][payload._id] =
        _b.bets[payload.user][payload._id] || {};
      _b.bets[payload.user][payload._id] = {
        ..._b.bets[payload.user][payload._id],
        ...payload,
      };
    })
    .addCase(addBets, (_b, { payload }) => {
      for (let index = 0; index < payload.length; index++) {
        const bet = payload[index];

        _b.bets[bet.user] = _b.bets[bet.user] || {};
        _b.bets[bet.user][bet._id] = _b.bets[bet.user][bet._id] || {};
        _b.bets[bet.user][bet._id] = {
          ..._b.bets[bet.user][bet._id],
          ...bet,
        };
      }
    })
    .addCase(addLivefeedBets, (_b, { payload }) => {
      const feed = _b.liveFeed || [];
      feed.push(...payload);
      _b.liveFeed = feed;
    }),
);
