import { getLiveFeedData } from "../../helpers/ServerApi";
import { useCallback } from "react";
import { useDispatch } from "react-redux";
import { addLivefeedBets } from "../../redux/bets/actions";

const useUpdateBalances = () => {
  const dispatch = useDispatch();

  return useCallback(async () => {
    await getLiveFeedData().then((balances) =>
      dispatch(addLivefeedBets(balances)),
    );
  }, [dispatch]);
};

export default useUpdateBalances;
