import "./style.sass";
import { addLivefeedBets } from "../../redux/bets/actions";
import { useDispatch } from "react-redux";
import { useEffect } from "react";
import { useGetLivefeedBets } from "../../redux/bets/hooks";
import useSocketIO from "../../hooks/useSocketIo";
import useUpdateLiveFeeds from "../../hooks/update/useUpdateLiveFeeds";
import { getGameLogo } from "../../helpers/logos";

const LiveFeed = () => {
  const socket = useSocketIO();
  const updateLiveFeeds = useUpdateLiveFeeds();
  const feed = useGetLivefeedBets();
  const dispatch = useDispatch();

  useEffect(() => {
    updateLiveFeeds();
    socket?.on("won:bet", (res) => dispatch(addLivefeedBets([res])));
  }, [dispatch, socket, updateLiveFeeds]);

  if (feed.length === 0) return <div />;

  return (
    <div id={"livefeed-main"}>
      <div className="live-marker">
        <div className="live-red-dot" />
        Live
      </div>
      {feed.map((item) => {
        const percentage =
          item.input === 0 ? 0 : ((item.prize || 0) / item.input) * 100;

        return (
          <div className="livefeed-row">
            <div
              className={"livefeed-card"}
              style={{ backgroundImage: `url(${getGameLogo('dice')})` }}
            />
            <div className="livefeed-data">
              <span>{item.username}</span> <b>{percentage.toFixed(3)}%</b>
            </div>
          </div>
        );
      })}
    </div>
  );
};

export default LiveFeed;
