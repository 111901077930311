import { Configuration } from "../helpers/interface";
import { AppStore } from "../redux";
import { BetsQueue } from "./BetsQueue";
import { Store } from "@reduxjs/toolkit";

export class Protocol {
  _config: Configuration;
  queue: BetsQueue;
  store: AppStore;

  constructor(config: Configuration, store: Store) {
    this.store = store;
    this._config = config;
    this.queue = new BetsQueue(this, store.dispatch);
  }
}
