import { IReferralBoard } from "../../../../../helpers/interface";

interface IProps {
  data: IReferralBoard;
  rank: number;
}

const ReferralRows = (props: IProps) => {
  const { data, rank } = props;

  return (
    <div className={"table-row"}>
      <div className={"table-row-li"}>{rank + 1}</div>
      <div className={"table-row-li"}>{data.referralCode}</div>
      <div className={"table-row-li"}>${(data.usdEarned || 0).toFixed(4)}</div>
      <div className={"table-row-li"}>{data.totalReferrals}</div>
    </div>
  );
};

export default ReferralRows;
